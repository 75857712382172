import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";

const TournamentQuestions = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [quizData, setQuizData] = useState<any>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<number, number>
  >({}); // Indices only
  const [timer, setTimer] = useState<number>(0); // Timer in seconds
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) throw new Error("No token found");

        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.get(
          `${backendUrl}/tournaments/attempt-tournament/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        if (response.status === 200) {
          const quiz = data.tournament;
          setQuizData(quiz);
          setTimer(quiz?.duration * 60); // Convert minutes to seconds
        } else {
          toast.error(data.message || "Failed to fetch quiz data.");
          navigate(-1); // Redirect back on failure
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [id, navigate]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    } else if (timer === 0 && quizData) {
      submitQuiz(); // Auto-submit when time is up
    }
  }, [timer, quizData]);

  const handleOptionChange = (
    questionIndex: number,
    selectedOption: number
  ) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: selectedOption,
    }));
  };

  const handleSubmit = () => {
    if (timer > 0) {
      setConfirmSubmit(true); // Display confirmation prompt
    } else {
      submitQuiz(); // Automatically submit when time is over
    }
  };

  const submitQuiz = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No token found");

      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const timeTaken = quizData?.duration * 60 - timer;
      const response = await axios.post(
        `${backendUrl}/tournaments/${id}/score`,
        {
          answers: Object.values(selectedAnswers),
          timeTaken: timeTaken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;

      if (response.status === 200) {
        toast.success("Quiz submitted successfully!");
        navigate(`/result/tournament/${id}`, { state: { results: data } });
      } else {
        toast.error(data.message || "Failed to submit quiz.");
      }
    } catch (error) {
      console.error("Error submitting quiz:", error);
      toast.error("An error occurred while submitting the quiz.");
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <PulseLoader color="#7A54F0" loading={loading} />
      </div>
    );
  }

  return (
    <div className="bg-white min-h-screen font-outfit px-4 py-6">
      {confirmSubmit && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
          <div className="bg-lightBlue p-6 rounded-3xl">
            <h2 className="text-xl text-center mb-4 text-[#313035]">
              Are you sure you want to submit the quiz?
            </h2>
            <div className="flex justify-end">
              <button
                className="px-4 py-2  text-[#6023D2] rounded"
                onClick={() => setConfirmSubmit(false)}
              >
                No
              </button>
              <button
                className="px-4 py-2  text-[#6023D2] rounded"
                onClick={submitQuiz}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <div className="text-primary text-lg font-semibold">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#000000"
            >
              <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
            </svg>
            <span>{formatTime(timer)} remaining</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-5 w-1/2 gap-4">
        {quizData?.questions.map((_: any, index: number) => (
          <button
            key={index}
            className={`w-8 h-8 flex items-center justify-center rounded ${
              currentQuestionIndex === index
                ? "bg-primary text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setCurrentQuestionIndex(index)}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <div className="p-6">
        <p className="mb-6">
          {currentQuestionIndex + 1}
          {".  "}
          {quizData?.questions[currentQuestionIndex].questionText}
        </p>

        {quizData?.questions[currentQuestionIndex].options.map(
          (option: string, index: number) => (
            <div key={index} className="mb-4">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name={`question-${currentQuestionIndex}`}
                  value={index} // Only sending index
                  checked={selectedAnswers[currentQuestionIndex] === index}
                  onChange={() =>
                    handleOptionChange(currentQuestionIndex, index)
                  }
                  className="form-radio h-5 w-5 accent-[#65558F]"
                />
                {option}
              </label>
            </div>
          )
        )}

        {currentQuestionIndex < quizData?.questions.length - 1 && (
          <button
            className="bg-primary text-white px-4 py-2 mt-6 rounded-lg w-full"
            onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
          >
            Next Question
          </button>
        )}

        {currentQuestionIndex === quizData?.questions.length - 1 && (
          <button
            className="bg-primary text-white px-4 py-2 mt-6 rounded-lg w-full"
            onClick={handleSubmit}
          >
            Submit Quiz
          </button>
        )}
      </div>
    </div>
  );
};

export default TournamentQuestions;
