import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Event {
  image: {
    id: string;
    url: string;
  };
  _id: string;
  name: string;
  pointsRequired: number;
  duration: number;
  numberOfQuestions: number;
  registeredEmails: string[];
  scheduledTime: string; // Added to store the scheduled time
}

const EventComponent: React.FC = () => {
  const navigate = useNavigate();
  const [eventList, setEventList] = useState<Event[]>([]);

  const fetchAllEvents = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const endpoint = `${backendUrl}/tournaments/all`;
      const response = await fetch(endpoint);
      const data = await response.json();

      if (data.status === "success") {
        setEventList(data.tournaments); // Updated field
      } else {
        console.error("Failed to fetch events:", data);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const convertUTCToIST = (utcTime: string) => {
    // Create a new Date object from the UTC time
    const scheduledTimeUTC = new Date(utcTime);

    // Convert the UTC time to IST (Indian Standard Time)
    const options: Intl.DateTimeFormatOptions = { timeZone: "Asia/Kolkata" };
    const istDate = new Date(scheduledTimeUTC.toLocaleString("en-US", options));

    return istDate;
  };

  const calculateTimeLeft = (scheduledTime: string) => {
    const scheduledTimeIST = convertUTCToIST(scheduledTime);
    const currentTime = new Date();

    // Ensure both times are in the same time zone (IST)
    const timeLeft = scheduledTimeIST.getTime() - currentTime.getTime();

    // If the event time has passed, set time left to zero
    if (timeLeft <= 0) {
      return "0d 0h 0m 0s"; // Time is over, stop at 00:00
    }

    // Calculate days, hours, minutes, and seconds left
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return `${daysLeft}d ${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`;
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  return (
    <div>
      <div className="my-4">
        {eventList?.length > 0 ? (
          eventList.map((event) => (
            <div
              key={event._id}
              className="px-6 py-3 rounded-[24px] shadow-default mb-3 bg-white border-[#C3B7F9] flex flex-col gap-5"
            >
              <div className="flex flex-row w-full gap-4 items-center">
                <div>
                  <img
                    src={event?.image?.url}
                    className="rounded-xl h-[48px] w-[48px]"
                    alt="event_image"
                  />
                </div>
                <div className="flex-grow">
                  <p className="text-text font-outfit font-medium text-[16px] truncate">
                    {event.name}
                  </p>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px]">
                      Questions: {event.numberOfQuestions}
                    </p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px]">
                      Duration: {event.duration} minutes
                    </p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px] flex items-center gap-1">
                      Price:
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7"
                          cy="7"
                          r="6.25"
                          stroke="black"
                          strokeWidth="1.5"
                        />
                        <text
                          x="50%"
                          y="55%"
                          fontSize="8"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fill="black"
                        >
                          ₹
                        </text>
                      </svg>
                      {event.pointsRequired}
                    </p>
                  </div>
                </div>
                <button
                  className="max-w-[74px] px-4 py-2 bg-primary text-white text-[14px] font-medium rounded-xl"
                  onClick={() => navigate(`/event-details/${event._id}`)}
                >
                  <div className="flex items-center justify-center gap-2">
                    <img
                      src="/icons/duocoin.png"
                      alt="wallet_icon"
                      className="w-[18px] h-[18px]"
                    />
                    <span className="w-5">{event.pointsRequired}</span>
                  </div>
                </button>
              </div>

              {/* Bottom section */}
              <div className="flex justify-between text-[12px] text-[#313035] font-light mt-2">
                <p>Time Left: {calculateTimeLeft(event?.scheduledTime)}</p>
                <p>People Enrolled: {(event?.registeredEmails)?event?.registeredEmails.length:0}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-sm font-roboto text-greyText font-bold">
            No events currently available.
          </div>
        )}
      </div>
    </div>
  );
};

export default EventComponent;
