// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
import FormInput from "../components/FormInput";

function ResetPassword() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  //   const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [sent, setsent] = useState(false);
  const { mail } = useParams();
  const { otp } = useParams();
  //   useEffect(() => {
  //     setEmail(decodeURIComponent(mail));
  //     console.log("Mail", email);
  //   }, []);
  const updatePassword = async () => {
    setIsResetLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/auth/reset-password`, {
        email: mail,
        otp : otp,
        newPassword: password,
      });

      toast.success(res.data.message);
      setIsResetLoading(false);
      setsent(true);
      navigate("/login");
    } catch (err) {
      console.log(err.response.data.message);
      setIsResetLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col min-h-screen my-8 font-outfit">
        <div className="flex flex-row items-center justify-between px-4">
          <IoIosArrowDropleft
            size={34}
            className={` ${
              mail.trim() ? " cursor-pointer" : "cursor-not-allowed"
            }`}
            color={COLORS.purple}
            onClick={() => {
              if (mail.trim()) {
                navigate(`/verify/code/${encodeURIComponent(mail)}`);
              }
            }}
          />
          <Link to="/login">
            <p className="font-outfit font-medium text-[14px] text-primary">Skip</p>
          </Link>
        </div>
        <div className="my-2 px-4">
          <div className="bg-white mt-14 rounded-2xl">
            <h4 className="text-text text-[20px] text-center">
              Forgot password
            </h4>
            <div className="mt-8">
              <span className="text-[#101010] text-[20px]">Reset Password</span>
              <span className="text-[#878787] font-outfit text-[14px] block">
                Your new password must be different from the previously used
                password
              </span>
            </div>
            <div>
              <div className="mt-6 flex flex-col gap-y-3 ">
                <div>
                  <FormInput
                    type="password"
                    placeholder="Enter your Password"
                    className=""
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mt-6">
                  <FormInput
                    type="password"
                    placeholder="Re-enter your Password"
                    className=""
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />{" "}
                </div>

                {!isResetLoading ? (
                  <ActionButton
                    buttonClass="bg-primary rounded-[12px] mt-8 "
                    buttonText="Save"
                    onClick={updatePassword}
                  />
                ) : (
                  <div className="bg-primary h-10 p-4 flex justify-between items-center rounded-[12px]">
                    <LoaderCircle className="animate-spin text-white" />
                    <div className="text-white">Saving New Password...</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
