import React from "react";

interface LogoProps {
    className?: string
}


const Logo = ({className}: LogoProps) => {
  return (
    <div>
      <img src="/logos/duocortex-logo.svg" alt="logo" />
    </div>
  );
};

export default Logo;
