import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Quiz {
  image: {
    id: string;
    url: string;
  };
  _id: string;
  name: string;
  pointsRequired: number;
  duration: number;
  semester: number;
  numberOfQuestions: number;
}

const QuizComponent: React.FC = () => {
  const navigate = useNavigate();
  const [quizeList, setQuizeList] = useState<Quiz[]>([]);
  const [selectedSemester, setSelectedSemester] = useState<number>(1); // Default to Semester 1

  const fetchQuizzesBySemester = async (semester: number) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const endpoint = `${backendUrl}/quizzes/semester/${semester}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      if (data.status === "success") {
        setQuizeList(data.quizzes);
      } else {
        console.error("Failed to fetch quizzes:", data);
      }
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };

  useEffect(() => {
    fetchQuizzesBySemester(selectedSemester);
  }, [selectedSemester]);

  const handleSemesterClick = (semester: number) => {
    setSelectedSemester(semester);
    fetchQuizzesBySemester(semester);
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <ul className="flex flex-row gap-2 mb-6">
          {Array.from({ length: 8 }, (_, index) => (
            <li key={index}>
              <button
                onClick={() => handleSemesterClick(index + 1)}
                className={`flex flex-row justify-center items-center text-[14px] font-outfit font-medium w-[72px] h-[32px] rounded-[8px] ${
                  selectedSemester === index + 1
                    ? "bg-[#C1B9FC] text-text"
                    : "bg-white border-[#CAC4D0] border-[1px]"
                }`}
              >
                Sem {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="my-4">
        {quizeList.length > 0 ? (
          quizeList.map((quiz) => (
            <div
              key={quiz._id}
              className="px-6 py-3 rounded-[24px] shadow-default mb-3 bg-white border-[#C3B7F9] flex gap-5 items-center"
            >
              <div className="flex flex-row w-full gap-4 items-center">
                <div>
                  <img
                    src={quiz?.image?.url}
                    className="rounded-xl h-[48px] w-[48px]"
                    alt="quiz_image"
                  />
                </div>
                <div className="flex-grow">
                  <p className="text-text font-outfit font-medium text-[16px] truncate">
                    {quiz.name}
                  </p>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px]">
                      Questions: {quiz.numberOfQuestions}
                    </p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px]">
                      Duration: {quiz.duration} minutes
                    </p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <p className="text-[#313035] font-light text-[12px] flex items-center gap-1">
                      Price:
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7"
                          cy="7"
                          r="6.25"
                          stroke="black"
                          strokeWidth="1.5"
                        />
                        <text
                          x="50%"
                          y="55%"
                          fontSize="8"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fill="black"
                        >
                          ₹
                        </text>
                      </svg>
                      {quiz.pointsRequired}
                    </p>
                  </div>
                </div>
                <button
                  className="max-w-[74px] px-4 py-2 bg-primary text-white text-[14px] font-medium rounded-xl"
                  onClick={() => navigate(`/quiz-details/${quiz._id}`)}
                >
                  <div className="flex items-center justify-center gap-2">
                    <img
                      src="/icons/duocoin.png"
                      alt="wallet_icon"
                      className="w-[18px] h-[18px]"
                    />
                    <span className="w-5">{quiz.pointsRequired}</span>
                  </div>
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-sm font-roboto text-greyText font-bold">
            No quizzes currently available.
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizComponent;
