import React, { useCallback } from "react";
import Heading from "../../components/Heading";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import { Avatar, IconButton } from "@mui/material";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useState, useRef, useEffect } from "react";
import { useRoom } from "../../utils/Room";
import { useAuth } from "../../context/AuthContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BACKEND_URL ="http://localhost:5000"
const demoChat = [
  {
    msg: "Hello",
    time: "9:24",
    isMe: true,
  },
  {
    msg: "Hi",
    time: "9:24",
  },
  {
    msg: "How are u?",
    time: "9:30",
  },
  {
    msg: "Busy?",
    time: "9:40",
    isMe: true,
  },
  {
    msg: "No what is problem?",
    time: "9:41",
  },
];
const GroupChat = () => {
  const { currRoom, messages, msg, setMsg, handleChat, handleFileUpload } =
    useRoom();
    // console.log("Current room in group cahst ",currRoom)
    const [toolsPop,setToolsPop]=useState(false);

  const { account } = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const handletime = (time: string) => {
    return new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (ref.current) {
      const container = ref.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  const [image, setImage] = useState(false);

  async function getS3SignUrl(filename: string, filetype: string) {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ fileName: filename, fileType: filetype }),
    };
    const response = await fetch(`${BACKEND_URL}/v1/user/uploadImage`, options);
    const presignedUrl = await response.json();
    return presignedUrl;
  }
  async function pushProfilePhotoToS3(presignedUrl: string, file: File) {
    const myHeaders = new Headers({ "Content-Type": file.type });
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
    return response;
  }
  function getUrlUpToExtension(url: string) {
    const queryParamIndex = url.indexOf("?");
    const endIndex = queryParamIndex !== -1 ? queryParamIndex : url.length;
    return url.substring(0, endIndex);
  }
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      const file = acceptedFiles[0];
      const data = await getS3SignUrl(file.name, file.type);
      console.log(data, "data");
      if (data.url) {
        const response = await pushProfilePhotoToS3(data.url, file);
        if (response) {
          console.log("Upload successful");
          // toast.success("Profile photo updated successfully");
          handleFileUpload(getUrlUpToExtension(response.url));
          // navigate("/interest");
          // setupload(true);
          // setFileLink(getUrlUpToExtension(response.url));
        } else {
          console.error("Upload failed");
          toast.error("Upload failed");
          // setupload(false);
        }
      }
    },
    [image]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  return (
    <div className="pt-[0.5rem] text-md flex flex-col gap-5 h-screen">
      <Heading title={currRoom?.name} />
      <div className=" h-full w-full mt-16  flex flex-col flex-1 fixed">
        <p className="text-center text-lightgrey">Today</p>
        <div className="bg-[#F2F6FA] px-[1rem] flex-1 h-[80vh] flex flex-col justify-end relative bottom-0 mb-28 ">
          <div
            className="overflow-y-auto flex flex-col gap-3 py-5 no-scroll"
            ref={ref}
          >
            {messages.length > 0 &&
              messages.map((item: any) =>
                item?.sender == account?.email ? (
                  <div className=" flx-row gap-2 justify-end">
                    <p className="py-3 pl-3 pr-6 bg-white rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-md flx-row gap-3">
                      {item.type === "link" ? (
                        <img
                          src={item.message}
                          alt="image"
                          className="w-20 h-20 mx-auto"
                          onClick={() => {
                            window.open(item.message);
                          }}
                        />
                      ) : (
                        item.message
                      )}
                      <span className="text-lightgrey">
                        {handletime(item.time)}
                      </span>
                      <span>
                        <DoneAllRoundedIcon className="text-purple" />
                      </span>
                    </p>
                    <Avatar
                      alt="Remy Sharp"
                      src={item.image}
                    />
                  </div>
                ) : (
                  <div className=" flx-row gap-2 w-2/3 ">
                    <Avatar
                      alt="Remy Sharp"
                      src={item.image}
                    />
                    <p className="py-3 pl-3 pr-6 bg-white rounded-tl-2xl rounded-tr-2xl rounded-bl-md rounded-br-2xl flx-row gap-3">
                      <span className="text-lightgrey">
                        {handletime(item.time)}
                      </span>
                      {item.type === "link" ? (
                        <img
                          src={item.message}
                          alt="image"
                          className="w-20 h-20 mx-auto"
                        />
                      ) : (
                        item.message
                      )}
                    </p>
                  </div>
                )
              )}
          </div>
        </div>
        <div className="w-full fixed bottom-0 flex flex-col gap-3">
          {toolsPop && <div className="h-12 bg-white border border-[#E1E8F1] px-3 flx-row justify-center gap-3 w-2/3 mx-auto">
            <IconButton>
              <CameraAltOutlinedIcon className="text-purple" />
            </IconButton>
            <IconButton {...getRootProps()}>
              <input {...getInputProps()} />

              <AddOutlinedIcon className="text-purple" />
            </IconButton>
            <IconButton>
              <KeyboardVoiceOutlinedIcon className="text-purple" />
            </IconButton>
          </div>}

          <div className="h-12 gap-5 bg-white border border-[#E1E8F1] px-3 flx-row justify-between w-full">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg" onClick={()=>setToolsPop(!toolsPop)} className="cursor-pointer"
            >
              <path d="M8 8V14H6V8H0V6H6V0H8V6H14V8H8Z" fill="#ADB5BD" />
            </svg>

            <input
              type="text"
              placeholder="Type a message"
              className="p-2 w-full bg-[#F7F7FC]"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              
            />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer"
              onClick={handleChat}
            >
              <g clip-path="url(#clip0_431_2459)">
                <path
                  d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z"
                  fill="#002DE3"
                />
              </g>
              <defs>
                <clipPath id="clip0_431_2459">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          </div>
      </div>
    </div>
  );
};

export default GroupChat;
