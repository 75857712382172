import React from "react";

interface FormInputProps {
  placeholder: string;
  type: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  className?: string;
  style?: any;
  options?: any;
  suffix?: any;
  min?: string;
  max?: string;
}

const FormInput = ({
  placeholder,
  type,
  value,
  onChange,
  className,
  style,
  options,
  suffix,
  min,
  max,
}: FormInputProps) => {
  if (type === "date") {
    return (
      <input
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        type="text"
        className={`w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px] text-formfieldtext border-[1px] border-primary ${className}`}
        onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
        min={min}
        max={max}
      />
    );
  }

  return type === "dropdown" ? (
    <select
      className={`w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px placeholder:text-[#525058] ${className}`}
      style={style}
      value={value}
      onChange={onChange}
    >
      <option selected className="text-[14px]">{placeholder}</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value} className="text-[14px]">
          {option.label}
        </option>
      ))}
    </select>
  ) : (
    <div className="relative">
      <input
        className={`w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px] text-formfieldtext placeholder:text-[#525058] border-[1px] border-primary ${className}`}
        style={style}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
      <div className="absolute top-6 right-4">{suffix}</div>
    </div>
  );
};

export default FormInput;
