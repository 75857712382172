import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../pages/SideBar";
import userService from "../services/userService";

const Navbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [profileData, setProfileData] = useState<any>(null);
  useEffect(()=>{
    getProfile();
  },[])
  const getProfile = async () => {
    try {
      const res = await userService.getProfile();
      setProfileData(res);
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <div className="sticky">
      <div className="h-20 flex justify-between px-4 items-center">
        <div>
          <Link to="/">
            <img src="/logos/Duo-logo-small.svg" className="w-8 h-8" />
          </Link>
        </div>
        <div className="flex items-center gap-8">
          {/* <div>
            <img src="/icons/Bell-icon.svg" className="w-7 h-7" />
          </div> */}
          <div className="items-center">
            <button
              onClick={() => {
                setOpenSidebar(!openSidebar);
              }}
            >
              <img src="/icons/Hamburger-icon.svg" className="w-7 h-7" />
            </button>
          </div>
        </div>
      </div>
      {openSidebar && (
        <div className="fixed h-screen top-0 right-0 z-50">
          <SideBar profileData={profileData} />
        </div>
      )}
    </div>
  );
};

export default Navbar;
