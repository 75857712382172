import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { userPostApis } from "../services/forumService";
import { api } from "../config/apiConfig";

export const useForum = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<File | null>(null); // Change to File type

  const formInputSource = [
    {
      type: "text",
      placeholder: "Start your topic",
      value: title,
      onChange: (e: any) => setTitle(e.target.value),
    },
  ];

  const [posts, setPosts] = useState([]);
  const [currPost, setCurrPost] = useState();
  const {
    createPost,
    getCurrPost,
    makeComment,
    connectFriend,
    repost,
    FriendStatus,
    makeReply,
  } = userPostApis();

  const handleCreatePost = async () => {
    const data = new FormData(); // Create a FormData object to handle the file and other fields
    data.append("title", title);
    data.append("description", description);
    if (!title || !description) {
      toast.error("Please enter all the fields");
    }
    if (image) data.append("file", image); // Append the image if selected

    try {
      console.log("Creating post with data:", data);

      await createPost(data); // Sending FormData as the payload

      toast.success("Post created successfully!");
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetUserPosts = useCallback(async () => {
    try {
      const getPosts = await api.get("/posts/all");
      setPosts(getPosts.data);
    } catch (e: any) {
      console.log(e?.response?.data?.message);
    }
  }, [setPosts]);

  useEffect(() => {
    handleGetUserPosts();
  }, [handleGetUserPosts]);

  const handleGetCurrPost = useCallback(
    async (id: string) => {
      try {
        return await getCurrPost(id);
      } catch (e: any) {
        console.log(e);
      }
    },
    [currPost]
  );

  const handleRepost = async (postId: string) => {
    try {
      const res = await repost({ postId });
      toast.success("Post reposted successfully!");
      window.location.reload();
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleComment = async (
    postId: string,
    comment: string,
    setPostComments: any,
    parentId?: string
  ) => {
    try {
      let payload = {
        text: comment,
      };
      await makeComment({
        comment,
        postId,
        setPostComments,
        parentId: parentId ? parentId : null,
        payload,
      });
      toast.success("Comment added successfully!");
    } catch (e) {
      console.log(e);
    }
  };

  const handleConnectFriend = async (
    payload: any,
    currPost: any,
    setFriendStatus: any
  ) => {
    try {
      await connectFriend(payload);
      FriendStatus(currPost?.createdBy?._id, setFriendStatus);
      toast.success("Friend request sent successfully!");
    } catch (e: any) {
      console.log(e);
    }
  };

  return {
    formInputSource,
    handleCreatePost,
    handleGetCurrPost,
    posts,
    handleComment,
    handleConnectFriend,
    handleRepost,
    image,
    setImage,
    description,
    setDescription,
    FriendStatus,
    makeReply,
  };
};
