export const streams = [
  { label: "MBBS", value: "mbbs" },
  { label: "B.Sc.", value: "bsc" },
  { label: "Nursing", value: "nursing" },
  { label: "M.Sc.", value: "msc" },
  { label: "Post Graduate", value: "post_graduate" },
  { label: "Psychology", value: "psychology" },
];

export const yearsOfStudy = [
  { label: "First Year", value: "First Year" },
  { label: "Second Year", value: "Second Year" },
  { label: "Third Year", value: "Third Year" },
  { label: "Fourth Year", value: "Fourth Year" },
  { label: "Fifth Year", value: "Fifth Year" },
  { label: "Graduate", value: "Graduate" },
  { label: "Postgraduate", value: "Postgraduate" },
];

export const colleges = [
  {
    label: "Dayananda Sagar University, Bangalore",
    value: "dayanandasagaruniversity,bangalore",
  },
  { label: "Rajasthan University", value: "rajasthanuniversity" },
  { label: "The Kalahandi university", value: "thekalahandiuniversity" },
  { label: "JIS University, Kolkata", value: "jisuniversity,kolkata" },
  { label: "CSJM University Kanpur", value: "csjmuniversitykanpur" },
  { label: "Ranchi University", value: "ranchiuniversity" },
  {
    label: "Statutory Autonomous, AIIMS (Bilaspur)",
    value: "statutoryautonomous,aiims(bilaspur)",
  },
  { label: "Kolhan University, Chaibasa", value: "kolhanuniversity,chaibasa" },
  { label: "Mizoram University", value: "mizoramuniversity" },
  {
    label: "Integral University, Lucknow",
    value: "integraluniversity,lucknow",
  },
  {
    label: "Krishna Vishwa Vidyapeeth, Karad, Maharashtra",
    value: "krishnavishwavidyapeeth,karad,maharashtra",
  },
  {
    label: "Nitte University (Deemed), Mangalore",
    value: "nitteuniversity(deemed),mangalore",
  },
  { label: "Adesh University, Bathinda", value: "adeshuniversity,bathinda" },
  {
    label: "Sikkim Manipal Univ. of Health,Medical & Tech. Scs",
    value: "sikkimmanipalunivofhealth,medical&techscs",
  },
  {
    label: "Hemchandracharya North Gujarat University,Patan",
    value: "hemchandracharyanorthgujaratuniversity,patan",
  },
  { label: "Punjab University", value: "punjabuniversity" },
  {
    label: "Statutory Autonomous, AIIMS(Bhopal)",
    value: "statutoryautonomous,aiims(bhopal)",
  },
  {
    label: "Sumandeep Vidyapeeth University (Deemed), Vadodra",
    value: "sumandeepvidyapeethuniversity(deemed),vadodra",
  },
  { label: "SGT University, Gurugram", value: "sgtuniversity,gurugram" },
  { label: "Pondicherry University", value: "pondicherryuniversity" },
  { label: "Berhampur University", value: "berhampuruniversity" },
  { label: "Manipur University", value: "manipuruniversity" },
  {
    label: "Saveetha University (Deemed), Chennai",
    value: "saveethauniversity(deemed),chennai",
  },
  { label: "Vikram University, Ujjain", value: "vikramuniversity,ujjain" },
  {
    label: "KLE Academy of Higher Education & Research (Deemed), Belgaum",
    value: "kleacademyofhighereducation&research(deemed),belgaum",
  },
  { label: "Al-Falah University", value: "al-falahuniversity" },
  {
    label: "M.S. Ramaiah University of Applied Sciences, Bangalore.",
    value: "msramaiahuniversityofappliedsciences,bangalore",
  },
  {
    label: "Kerala University, Thiruvananthapuram",
    value: "keralauniversity,thiruvananthapuram",
  },
  { label: "Santosh University", value: "santoshuniversity" },
  {
    label: "Sri Guru Ram Das University of Health Sciences, Sri Amritsar",
    value: "srigururamdasuniversityofhealthsciences,sriamritsar",
  },
  {
    label: "Amrita Vishwa Vidyapeetham University (Deemed), Coimbatore",
    value: "amritavishwavidyapeethamuniversity(deemed),coimbatore",
  },
  {
    label: "Al-Karim University, Katihar",
    value: "al-karimuniversity,katihar",
  },
  {
    label: "Sri Siddhartha Academy of Higher Education, Tumkur",
    value: "srisiddharthaacademyofhighereducation,tumkur",
  },
  {
    label: "Statutory Autonomous, AIIMS (Vijaypur)",
    value: "statutoryautonomous,aiims(vijaypur)",
  },
  { label: "Utkal University", value: "utkaluniversity" },
  {
    label: "University of Jamia Hamdard (Hamdard University)",
    value: "universityofjamiahamdard(hamdarduniversity)",
  },
  {
    label:
      "Hemwati Nandan Bahuguna Uttarakhand Medical Education University, Dehradun",
    value:
      "hemwatinandanbahugunauttarakhandmedicaleducationuniversity,dehradun",
  },
  {
    label: "North Eastern Hill University, Shillong",
    value: "northeasternhilluniversity,shillong",
  },
  {
    label: "North Odisha University, Baripada, Odisha",
    value: "northodishauniversity,baripada,odisha",
  },
  {
    label:
      "Sri Ramachandra Institute of Higher Education & Research(Deemed to be University), Chennai",
    value:
      "sriramachandrainstituteofhighereducation&research(deemedtobeuniversity),chennai",
  },
  { label: "Annamalai University", value: "annamalaiuniversity" },
  { label: "Jammu University", value: "jammuuniversity" },
  {
    label: "Madhya Pradesh Medical Science University, Jabalpur",
    value: "madhyapradeshmedicalscienceuniversity,jabalpur",
  },
  {
    label: "King Georges Medical University,Lucknow",
    value: "kinggeorgesmedicaluniversity,lucknow",
  },
  {
    label: "Uttar Pradesh University of Medical Sciences, Etawah",
    value: "uttarpradeshuniversityofmedicalsciences,etawah",
  },
  { label: "M J P Rohilkhand University", value: "mjprohilkhanduniversity" },
  {
    label: "Rajasthan University of Health Sciences",
    value: "rajasthanuniversityofhealthsciences",
  },
  {
    label: "Rani Durgavati Vishwa Vidyalaya, Jabalpur",
    value: "ranidurgavativishwavidyalaya,jabalpur",
  },
  {
    label: "Baba Farid University of Health Sciences,Faridkot",
    value: "babafariduniversityofhealthsciences,faridkot",
  },
  { label: "Tantia University", value: "tantiauniversity" },
  { label: "LNCT University, Bhopal", value: "lnctuniversity,bhopal" },
  {
    label:
      "Dhanalakshmi Srinivasan University, a state private University of Tamil Nadu",
    value:
      "dhanalakshmisrinivasanuniversity,astateprivateuniversityoftamilnadu",
  },
  { label: "Barkatullah University", value: "barkatullahuniversity" },
  {
    label: "Sharda University, Greater Noida",
    value: "shardauniversity,greaternoida",
  },
  {
    label: "NIMS University (Deemed), Jaipur",
    value: "nimsuniversity(deemed),jaipur",
  },
  {
    label:
      "Dr. MGR Educational and Research Institute (Deemed University), Chennai",
    value: "drmgreducationalandresearchinstitute(deemeduniversity),chennai",
  },
  {
    label: "Sankalchand Patel University",
    value: "sankalchandpateluniversity",
  },
  {
    label:
      "Vels Institute Of Science, Technology & Advanced Studies( VISTAS) ( Deemed to be University)",
    value:
      "velsinstituteofscience,technology&advancedstudies(vistas)(deemedtobeuniversity)",
  },
  {
    label: "Hemwati Nandan Bahuguna Uttarakhand Medical Education University",
    value: "hemwatinandanbahugunauttarakhandmedicaleducationuniversity",
  },
  {
    label: "Maharishi Marakandeshwar University, Kumarhatti, Solan",
    value: "maharishimarakandeshwaruniversity,kumarhatti,solan",
  },
  {
    label: "Maharashtra University of Health Sciences, Nashik",
    value: "maharashtrauniversityofhealthsciences,nashik",
  },
  { label: "Bundelkhand University", value: "bundelkhanduniversity" },
  { label: "Kashmir University", value: "kashmiruniversity" },
  {
    label: "Gopal Narayan Singh University",
    value: "gopalnarayansinghuniversity",
  },
  {
    label:
      "Pt. Deendayal Upadhyay Memorial Health Sciences and Ayush University, Raipur",
    value: "ptdeendayalupadhyaymemorialhealthsciencesandayushuniversity,raipur",
  },
  {
    label: "Noida International University",
    value: "noidainternationaluniversity",
  },
  {
    label: "Jaipur National University (Private Univ.)",
    value: "jaipurnationaluniversity(privateuniv)",
  },
  {
    label: "Dr. YSR University of Health Sciences",
    value: "drysruniversityofhealthsciences",
  },
  {
    label: "D.Y. Patil Education Society (Deemed University), Kolhapur",
    value: "dypatileducationsociety(deemeduniversity),kolhapur",
  },
  { label: "Khaja Bandanawaz University", value: "khajabandanawazuniversity" },
  { label: "Sri Aurobindo University", value: "sriaurobindouniversity" },
  {
    label: "Malwanchal University, Indore",
    value: "malwanchaluniversity,indore",
  },
  {
    label: "MGM Institute of Health Sciences (Deemed University), Navi Mumbai",
    value: "mgminstituteofhealthsciences(deemeduniversity),navimumbai",
  },
  {
    label: "SRM Institute  of Science & Technology (Formerly SRM University)",
    value: "srminstituteofscience&technology(formerlysrmuniversity)",
  },
  {
    label: "Maharaja Krishnakumarsinghji Bhavnagar University",
    value: "maharajakrishnakumarsinghjibhavnagaruniversity",
  },
  { label: "Bhaikaka University", value: "bhaikakauniversity" },
  { label: "Amaltas University, Dewas", value: "amaltasuniversity,dewas" },
  {
    label: "Vinayaka Missions University (Deemed), Salem",
    value: "vinayakamissionsuniversity(deemed),salem",
  },
  { label: "Manipal University, Manipal", value: "manipaluniversity,manipal" },
  {
    label: "Maharishi Marakandeshwar University (Deemed), Ambala",
    value: "maharishimarakandeshwaruniversity(deemed),ambala",
  },
  {
    label: "Sri Balaji Vidyapeeth (Deemed University), Pondicherry",
    value: "sribalajividyapeeth(deemeduniversity),pondicherry",
  },
  { label: "B.N. Mandal University", value: "bnmandaluniversity" },
  {
    label: "Manipal Academy of Higher Education (Deemed University), Manipal",
    value: "manipalacademyofhighereducation(deemeduniversity),manipal",
  },
  { label: "Gujarat University", value: "gujaratuniversity" },
  {
    label: "Sri Sathya Sai University for Human Excellence",
    value: "srisathyasaiuniversityforhumanexcellence",
  },
  { label: "Jiwaji University", value: "jiwajiuniversity" },
  {
    label: "Statutory Autonomous, AIIMS (Madurai)",
    value: "statutoryautonomous,aiims(madurai)",
  },
  { label: "Goa University", value: "goauniversity" },
  {
    label: "Siksha O Anusandhan University,Bhubaneswar",
    value: "sikshaoanusandhanuniversity,bhubaneswar",
  },
  {
    label: "Dharmsinh Desai University, Nadiad",
    value: "dharmsinhdesaiuniversity,nadiad",
  },
  { label: "Peoples University, Bhopal", value: "peoplesuniversity,bhopal" },
  { label: "Parul University, Vadodara", value: "paruluniversity,vadodara" },
  { label: "Anurag University", value: "anuraguniversity" },
  {
    label:
      "Chettinad Academy of Research and Education (Deemed University), Chettinad",
    value: "chettinadacademyofresearchandeducation(deemeduniversity),chettinad",
  },
  { label: "Sidho Kanhu Murmu University", value: "sidhokanhumurmuuniversity" },
  {
    label: "Binod Bihati Mahto Koyalanchal University, Dhanbad",
    value: "binodbihatimahtokoyalanchaluniversity,dhanbad",
  },
  {
    label:
      "Pandit Deendayal Upadhyay Memorial Health Science & Ayush University of Chattisgarh",
    value:
      "panditdeendayalupadhyaymemorialhealthscience&ayushuniversityofchattisgarh",
  },
  {
    label: "Padmashree Dr. D Y Patil University (Deemed), Navi Mumbai",
    value: "padmashreedrdypatiluniversity(deemed),navimumbai",
  },
  {
    label: "Shri Venkateshwara University, Gajraula",
    value: "shrivenkateshwarauniversity,gajraula",
  },
  {
    label: "Teerthanker Mahaveer University, Moradabad",
    value: "teerthankermahaveeruniversity,moradabad",
  },
  {
    label: "Shri Govind Guru University, Godhara",
    value: "shrigovindguruuniversity,godhara",
  },
  {
    label: "Statutory Autonomous, Puducherry",
    value: "statutoryautonomous,puducherry",
  },
  {
    label: "Geetanjali University , Udaipur",
    value: "geetanjaliuniversity,udaipur",
  },
  {
    label:
      "Pt.Ravishankar Shukla University (Prev. Ravishankar University),Raipur",
    value: "ptravishankarshuklauniversity(prevravishankaruniversity),raipur",
  },
  {
    label: "Statutory Autonomous, AIIMS(New Delhi)",
    value: "statutoryautonomous,aiims(newdelhi)",
  },
  {
    label: "Srimanta Shankardeva University of Health Sciences",
    value: "srimantashankardevauniversityofhealthsciences",
  },
  {
    label: "Bhupendra Narayan Mandal University, Madhepura",
    value: "bhupendranarayanmandaluniversity,madhepura",
  },
  {
    label: "Mahatma Gandhi Kashi Vidyapith, Varanasi",
    value: "mahatmagandhikashividyapith,varanasi",
  },
  {
    label: "Atal Bihari Vijpayee Medical University (ABVMU), Lucknow",
    value: "atalbiharivijpayeemedicaluniversity(abvmu),lucknow",
  },
  {
    label: "Sri Venkateswara Institute of Medical Sciences (SVIMS), Tirupati",
    value: "srivenkateswarainstituteofmedicalsciences(svims),tirupati",
  },
  {
    label: "Yenepoya University (Deemed Manglore)",
    value: "yenepoyauniversity(deemedmanglore)",
  },
  {
    label: "Bareilly International University, Bareilly",
    value: "bareillyinternationaluniversity,bareilly",
  },
  {
    label:
      "JSS Academy of Higher Education & Research, Mysuru (formerly known as JSS University, Mysore)",
    value:
      "jssacademyofhighereducation&research,mysuru(formerlyknownasjssuniversity,mysore)",
  },
  {
    label: "Fakir Mohan Univeristy, Balasore",
    value: "fakirmohanuniveristy,balasore",
  },
  { label: "MS University of Baroda", value: "msuniversityofbaroda" },
  {
    label: "Statutory Autonomous, AIIMS (Guwahati)",
    value: "statutoryautonomous,aiims(guwahati)",
  },
  { label: "Saurashtra University", value: "saurashtrauniversity" },
  { label: "MSCBD University, Baripada", value: "mscbduniversity,baripada" },
  { label: "Swaminayaran University", value: "swaminayaranuniversity" },
  {
    label: "Ras Bihari Bose Subharti University, Dehradun",
    value: "rasbiharibosesubhartiuniversity,dehradun",
  },
  { label: "Delhi University", value: "delhiuniversity" },
  {
    label: "Deemed University, Jammu & Kashmir",
    value: "deemeduniversity,jammu&kashmir",
  },
  {
    label: "Swami Vivekanand Subharti University, Meerut",
    value: "swamivivekanandsubhartiuniversity,meerut",
  },
  {
    label: "West Bengal University of Health Sciences,Kolkata",
    value: "westbengaluniversityofhealthsciences,kolkata",
  },
  {
    label:
      "Sri Devaraj Urs Academy of Higher Education and Research (Deemed University), Kolar",
    value:
      "sridevarajursacademyofhighereducationandresearch(deemeduniversity),kolar",
  },
  {
    label: "Dr B R Ambedkar University,Agra, U.P.",
    value: "drbrambedkaruniversity,agra,up",
  },
  {
    label: "Mahatma Gandhi University of Medical Science & Technology, Jaipur",
    value: "mahatmagandhiuniversityofmedicalscience&technology,jaipur",
  },
  { label: "Nagaland University", value: "nagalanduniversity" },
  { label: "Shri Guru Ram Rai University", value: "shrigururamraiuniversity" },
  { label: "University of Jammu", value: "universityofjammu" },
  {
    label: "HNB Uttarakhand Medical Education University, Dehradun",
    value: "hnbuttarakhandmedicaleducationuniversity,dehradun",
  },
  {
    label: "KSKV Kachchh University, Bhuj",
    value: "kskvkachchhuniversity,bhuj",
  },
  {
    label: "Dr. D Y Patil University (Deemed), Pimpri, Pune",
    value: "drdypatiluniversity(deemed),pimpri,pune",
  },
  {
    label: "The West Bengal University of Health Sciences",
    value: "thewestbengaluniversityofhealthsciences",
  },
  {
    label: "Statutory Autonomous, AIIMS(Raipur)",
    value: "statutoryautonomous,aiims(raipur)",
  },
  {
    label:
      "Pravara Institute of Medical Sciences (Deemed University), Ahmednagar",
    value: "pravarainstituteofmedicalsciences(deemeduniversity),ahmednagar",
  },
  {
    label: "Saurashtra University, Rajkot",
    value: "saurashtrauniversity,rajkot",
  },
  {
    label: "KIIT University (Deemed), Bhubaneswar",
    value: "kiituniversity(deemed),bhubaneswar",
  },
  {
    label: "Dr. Ram Manohar Lohia Avadh University, Faizabad",
    value: "drrammanoharlohiaavadhuniversity,faizabad",
  },
  {
    label: "The Tamilnadu Dr. MGR Medical University,Chennai",
    value: "thetamilnadudrmgrmedicaluniversity,chennai",
  },
  {
    label: "Mahatma Jyotiba Phule Rohilkhand University",
    value: "mahatmajyotibaphulerohilkhanduniversity",
  },
  {
    label:
      "Gandhi Institute of Technology and Management (GITAM University) Deemed, Visakhapatnam",
    value:
      "gandhiinstituteoftechnologyandmanagement(gitamuniversity)deemed,visakhapatnam",
  },
  {
    label: "Veer Narmad South Gujarat University",
    value: "veernarmadsouthgujaratuniversity",
  },
  { label: "Rajiv Gandhi University", value: "rajivgandhiuniversity" },
  {
    label:
      "Bharath Institute of Higher Education & Research (Deemed Univ.), Chennai",
    value: "bharathinstituteofhighereducation&research(deemeduniv),chennai",
  },
  {
    label: "Kerala University of Health Sciences, Thrissur, Kerala",
    value: "keralauniversityofhealthsciences,thrissur,kerala",
  },
  {
    label: "Statutory Autonomous, AIIMS (Rajkot)",
    value: "statutoryautonomous,aiims(rajkot)",
  },
  {
    label: "Ramchandra Chandravansi University, Bishrampur, Palamu",
    value: "ramchandrachandravansiuniversity,bishrampur,palamu",
  },
  {
    label: "Kaloji Narayana Rao University of Health Sciences, Warangal",
    value: "kalojinarayanaraouniversityofhealthsciences,warangal",
  },
  {
    label: "Bhakta Kavi Narsing Mehta University, Bikha Roa, Khadia",
    value: "bhaktakavinarsingmehtauniversity,bikharoa,khadia",
  },
  {
    label: "Prof. Rajendra Singh (Rajju Bhaiya) University, Prayagraj",
    value: "profrajendrasingh(rajjubhaiya)university,prayagraj",
  },
  {
    label: "Nilamber-Pitamber University",
    value: "nilamber-pitamberuniversity",
  },
  {
    label: "Symbiosis International (Deemed) University, pune",
    value: "symbiosisinternational(deemed)university,pune",
  },
  {
    label:
      "Datta Meghe Institute of Higher Education & Research (Declared as Deemed to be University)",
    value:
      "dattamegheinstituteofhighereducation&research(declaredasdeemedtobeuniversity)",
  },
  {
    label: "Statutory Autonomous, AIIMS(Deoghar)",
    value: "statutoryautonomous,aiims(deoghar)",
  },
  {
    label: "Sai Tirupati University, Udaipur",
    value: "saitirupatiuniversity,udaipur",
  },
  {
    label: "Ch. Charan Singh Universitiy, Meerut",
    value: "chcharansinghuniversitiy,meerut",
  },
  { label: "Kumaon University, Nainital", value: "kumaonuniversity,nainital" },
  { label: "C.S.J.M. University, Kanpur", value: "csjmuniversity,kanpur" },
  { label: "Aligarh Muslim University", value: "aligarhmuslimuniversity" },
  { label: "Banaras Hindu University", value: "banarashinduuniversity" },
  { label: "Calicut University", value: "calicutuniversity" },
  {
    label: "Bharati  Vidyapeeth University (Deemed), Pune",
    value: "bharatividyapeethuniversity(deemed),pune",
  },
  {
    label: "Swami Rama Himalayan University, Dehradun",
    value: "swamiramahimalayanuniversity,dehradun",
  },
  {
    label: "Rajiv Gandhi University of Health Sciences, Karnataka",
    value: "rajivgandhiuniversityofhealthsciences,karnataka",
  },
  {
    label: "Guru Gobind Singh Indraprastha University",
    value: "gurugobindsinghindraprasthauniversity",
  },
  { label: "University of Kashmir", value: "universityofkashmir" },
  { label: "Himachal Pradesh University", value: "himachalpradeshuniversity" },
  {
    label: "PT. B.D. Sharma University of Health Sciences, Rohtak",
    value: "ptbdsharmauniversityofhealthsciences,rohtak",
  },
  {
    label: "Shri Dharmasthala Manjunatheshwara University, Karnataka",
    value: "shridharmasthalamanjunatheshwarauniversity,karnataka",
  },
  {
    label: "Sri Venkateswaraa University, Chennai",
    value: "srivenkateswaraauniversity,chennai",
  },
  {
    label: "Atal Bihari Vajpayee Medical University, Lucknow, Uttar Pradesh",
    value: "atalbiharivajpayeemedicaluniversity,lucknow,uttarpradesh",
  },
  {
    label: "Deen Dayal Upadhyay Gorakhpur University, Gorakhpur",
    value: "deendayalupadhyaygorakhpuruniversity,gorakhpur",
  },
  { label: "Sambalpur University", value: "sambalpuruniversity" },
  {
    label: "Aryabhatta Knowledge University, Patna",
    value: "aryabhattaknowledgeuniversity,patna",
  },
  {
    label: "Indira Gandhi Instt of Medical Sciences (Deemed University), Patna",
    value: "indiragandhiinsttofmedicalsciences(deemeduniversity),patna",
  },
  {
    label: "Meenakshi University (Deemed), Chennai",
    value: "meenakshiuniversity(deemed),chennai",
  },
  { label: "Tripura University", value: "tripurauniversity" },
  {
    label: "All India Institute of Medical Sciences Bhopal",
    value: "All India Institute of Medical Sciences Bhopal",
  },
  {
    label: "All India Institute of Medical Sciences Bhubaneswar",
    value: "All India Institute of Medical Sciences Bhubaneswar",
  },
  {
    label: "All India Institute of Medical Sciences Delhi",
    value: "All India Institute of Medical Sciences Delhi",
  },
  {
    label: "All India Institute of Medical Sciences Jodhpur",
    value: "All India Institute of Medical Sciences Jodhpur",
  },
  {
    label: "All India Institute of Medical Sciences Patna",
    value: "All India Institute of Medical Sciences Patna",
  },
  {
    label: "All India Institute of Medical Sciences Raipur",
    value: "All India Institute of Medical Sciences Raipur",
  },
  {
    label: "All India Institute of Medical Sciences Rishikesh",
    value: "All India Institute of Medical Sciences Rishikesh",
  },
];
export const predefinedInterests = [
  "Anatomy",
  "Physiology",
  "Biochemistry",
  "Pharmacology",
  "Pathology",
  "Microbiology",
  "Clinical Medicine",
  "Surgery",
  "Internal Medicine"
];
