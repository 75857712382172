import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MatchUser from "../../pages/MatchUser";
const style = {
  position: "absolute" as "absolute",
  top: "45%",
  width: "100%",
  height: "55%",
  bgcolor: "background.paper",
  borderRadius: "15px 15px 0px 0px",
};
type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const MatchPopUp = ({ openForm, setOpenForm }: PopType) => {
  return (
    <div>
      <Modal open={openForm} onClose={() => setOpenForm(false)}>
        <Box sx={style} className="flex flex-col ">
          <MatchUser setOpenForm={setOpenForm} openForm={openForm} />
        </Box>
      </Modal>
    </div>
  );
};
export default MatchPopUp;
