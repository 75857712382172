import {api} from "../config/apiConfig";

const updateUser = async (data: any) => {
  const res = await api.put("/user/update", data);
  return res.data;
};

const getProfile = async () => {
  const res = await api.get("/user/user-details");
  return res.data;
};

export default { updateUser, getProfile };
