import React, { useContext, createContext, useState, useEffect } from "react";
import userService from "../services/userService";

type Account = {
  email: string;
  id: string;
  name: string;
  dob: string;
  active: boolean;
  gender: string;
  profilePic: string;
  collegeName: string;
  kyc: string;
  state: string;
  city: string;
  stream: string;
  yearofstudy: string;
  collegeId: string;
  rating: number;
};
type AccountValue = {
  account: Account | null;
  setAccount: React.Dispatch<React.SetStateAction<Account | null>>;
};
const defaultAccountValue: AccountValue = {
  account: null,
  setAccount: () => {},
};

const Data = createContext<AccountValue>(defaultAccountValue);

const AuthContext = ({ children }: { children: React.ReactNode }) => {
  const [account, setAccount] = useState<Account | null>(null);
  
  const value: AccountValue = {
    account,
    setAccount,
  };

  useEffect(() => {
    const loadAccount = async () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        try {
          const account_res = await userService.getProfile();
          setAccount(account_res.message)
        } catch (err) {
          console.log(err);
        }
        finally{
            console.log("Account ",account);}
      }
    };
    loadAccount();
  }, []);
  return <Data.Provider value={value}>{children}</Data.Provider>;
};

export default AuthContext;
export const useAuth = () => {
  return useContext(Data);
};