import { useEffect, useState } from "react";
import { api } from "../config/apiConfig";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { useForum } from "../utils/Forum";
import { toast } from "react-toastify";
import { getInstagramTimeFormat } from "../utils/CommonFunctions";

const Post = ({ item, profileData }: any) => {
  const { handleRepost } = useForum();
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [upvotes, setUpvotes] = useState(item?.upvotes.length);
  const [downvotes, setDownvotes] = useState(item?.downvotes.length);
  const [showThreeDotModal, setShowThreeDotModal] = useState(false);

  const handleLike = async (e: any) => {
    e.stopPropagation();
    setIsLiked(!isLiked);
    try {
      const res = await api.post(`/posts/${item._id}/upvote`);
      setUpvotes(res?.data?.upvotes.length);
      setDownvotes(res?.data?.downvotes.length);
    } catch (error) {
      setIsLiked(isLiked);
      console.error("Failed to like post", error);
    }
  };

  const handleDislike = async (e: any) => {
    e.stopPropagation();
    setIsDisliked(!isDisliked);
    try {
      const res = await api.post(`/posts/${item._id}/downvote`);
      setDownvotes(res?.data?.downvotes.length);
      setUpvotes(res?.data?.upvotes.length);
    } catch (error) {
      setIsDisliked(isDisliked);
      console.error("Failed to dislike post", error);
    }
  };

  const handleRepostClick = (e: any) => {
    e.stopPropagation();
    handleRepost(item._id);
  };

  const handleThreeDot = (e: any) => {
    e.stopPropagation();
    setShowThreeDotModal(true);
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    setShowThreeDotModal(false);
  };
  const handleDeletePost = async (e: any) => {
    try {
      const res = await api.delete(`/posts/${item._id}`);
      toast.success(res?.data?.message);
      window.location.reload();
    } catch (error) {
      console.error("Failed to delete post", error);
    }
  };

  useEffect(() => {
    if (item.upvotes.includes(profileData?._id)) {
      setIsLiked(true);
    }
    if (item.downvotes.includes(profileData?._id)) {
      setIsDisliked(true);
    }
  }, []);

  return (
    <div className="rounded-[20px] border text-[14px] bg-white flex flex-col p-6 shadow-default">
      {item?.repost && (
        <>
          <p className="pb-2 font-outfit">
            <span className="text-primary font-outfit">
              {item?.repostCreator?._id === profileData?._id ? (
                <>You</>
              ) : (
                <>{item?.repostCreator?.name}</>
              )}
            </span>{" "}
            reposted this
          </p>
          <hr className="mb-4" />
        </>
      )}
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center gap-3">
            <img
              src={item?.createdBy?.image?.url}
              className="h-12 w-12 rounded-[12px]"
            />
            <div className="flex flex-col font-outfit">
              <p className="font-medium text-text leading-[20px]">
                {item?.createdBy?.name}
              </p>
              <p className="font-light text-[12px] text-[#75737B]">
                {item?.createdBy?.collegeName} , {item?.createdBy?.yearOfStudy}
              </p>
              <p className="font-light text-[12px] text-[#75737B]">
                {item?.createdAt
                  ? getInstagramTimeFormat(new Date(item.createdAt))
                  : "Date not available"}
              </p>
            </div>
          </div>
          <div onClick={handleThreeDot}>
            <img src="/icons/three-dot.svg" />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-outfit text-[18px] leading-[22px] text-text">
            {item?.title}
          </p>
          <p className="font-outfit text-[14px] mt-2 leading-[20px] text-text">
            {item?.description}
          </p>
          {item?.image && (
            <div className="mt-4 bg-gray-100 rounded-2xl max-h-[1080px]">
              <img
                src={item?.image?.url}
                className="object-cover rounded-2xl mx-auto"
              />
            </div>
          )}
        </div>
        <div className="flex justify-between">
          <div className="flex gap-4 justify-between">
            <div
              className="flex-1 flex flex-row text-xs items-center"
              onClick={handleLike}
            >
              <button className="flex items-center mr-2">
                <img
                  src={
                    isLiked
                      ? "/icons/likefilled.svg"
                      : "/icons/likeoutlined.svg"
                  }
                  alt="like"
                  className="h-4 w-4"
                />
                <span className="ml-1 text-primary font-medium">{upvotes}</span>
              </button>
            </div>
            <div className="flex-1 flex flex-row text-xs items-center">
              <button
                className="flex items-center mr-2"
                onClick={handleDislike}
              >
                <img
                  src={
                    isDisliked
                      ? "/icons/dislikefilled.svg"
                      : "/icons/dislikeoutlined.svg"
                  }
                  alt="dislike"
                  className="h-4 w-4"
                />
                <span className="ml-1 text-primary font-medium">
                  {downvotes}
                </span>
              </button>
            </div>
          </div>
          <button className="text-[12px] flex gap-1 text-primary">
            <img src="/icons/comment-icon.svg" alt="Comments" />
            <p className="">{item?.comments?.length}</p>
          </button>
          {!item?.repost && item?.createdBy?._id !== profileData?._id && (
            <button
              className="text-xs flex-row text-greyText"
              onClick={handleRepostClick}
            >
              <LoopOutlinedIcon className="text-sm" />
              {item?.repostedBy?.length}
            </button>
          )}
        </div>
      </div>

      {showThreeDotModal && (
        <div
          className="fixed inset-0 bg-[#000]/50 flex justify-center items-center z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white rounded-lg p-4 w-64"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-medium">Post Options</h3>
            <div className="flex flex-col gap-4 mt-4">
              {item?.repost ? (
                <>
                  {item?.repostCreator?._id === profileData?._id && (
                    <>
                      {" "}
                      <button
                        className="text-red-500 font-medium"
                        onClick={handleDeletePost}
                      >
                        Delete Post
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {item?.createdBy?._id === profileData?._id ? (
                    <>
                      {" "}
                      <button
                        className="text-red-500 font-medium"
                        onClick={handleDeletePost}
                      >
                        Delete Post
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <button
                className="text-text font-medium"
                onClick={(e) => {
                  console.log("Report Post Clicked");
                  closeModal(e);
                }}
              >
                Report Post
              </button>
              <button className="text-text font-medium" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Post;
