import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import WalletHeader from "../../components/WalletHeader";
import { getProfile } from "../../utils/CommonFunctions";
import QuizComponent from "./QuizComponent"; // Import QuizComponent
import EventComponent from "./EventComponent";
import { useLocation } from "react-router-dom";

const QuizList: React.FC = () => {
  const location = useLocation(); // Use location to access state
  const [profileData, setProfileData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<"Quiz" | "Events">("Quiz");

  useEffect(() => {
    getProfile(setProfileData);

    // Check if activeTab is provided in the location state
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const handleTabChange = (tab: "Quiz" | "Events") => {
    setActiveTab(tab);
  };

  return (
    <>
      <Navbar />
      <div className="bg-lightestBlue min-h-screen pb-12 font-outfit">
        <div className="p-4 py-4 bg-white rounded-b-xl shadow-md">
          <div className="mb-3">
            <WalletHeader profileData={profileData} />
          </div>
        </div>
        <div className="px-4 py-4">
          <div className="flex flex-col justify-center">
            <div className="flex justify-between mb-6">
              <button
                onClick={() => handleTabChange("Quiz")}
                className={`w-1/2 py-2 text-center font-outfit text-[14px] font-medium leading-5 ${
                  activeTab === "Quiz"
                    ? "border-b-2 border-primary text-primary"
                    : ""
                }`}
              >
                <div className="flex items-center justify-center gap-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* Quiz Tab Icon */}
                  </svg>
                  <span>Quiz</span>
                </div>
              </button>
              <button
                onClick={() => handleTabChange("Events")}
                className={`w-1/2 py-2 text-center font-outfit text-[14px] font-medium leading-5 ${
                  activeTab === "Events"
                    ? "border-b-2 border-primary text-primary"
                    : ""
                }`}
              >
                <div className="flex items-center justify-center gap-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* Events Tab Icon */}
                  </svg>
                  <span>Events</span>
                </div>
              </button>
            </div>

            {activeTab === "Quiz" && <QuizComponent />}
            {activeTab === "Events" && <EventComponent />}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizList;

