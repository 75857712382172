import  { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Avatar, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { useRoom } from "../../utils/Room";
import { api } from "../../config/apiConfig";
import Navbar from "../../components/Navbar";

const Room = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { rooms, deleteChat } = useRoom();
  const [unreadCounts, setUnreadCounts] = useState<any>({});
  // console.log(rooms)
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const counts: any = {};
      for (const room of rooms) {
        if (!room.id) {
          continue;
        }
        const length = await getUnreadMessages(room.id);
        counts[room.id] = length;
      }
      setUnreadCounts(counts);
    };
    if (rooms.length > 0) {
      fetchUnreadMessages();
    }
  }, [rooms]);

  const getUnreadMessages = async (chatId: any) => {
    try {
      const response = await api.get(`chat/get-unreadmessage/${chatId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching unread messages:", error);
      return 0;
    }
  };
  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="pt-[0.5rem] text-md flex flex-col min-h-screen">
        <div className="flx-row mt-5 justify-between ">
          <div className="flx-row space-x-10 px-[1rem] text-darkgrey ">
            <Link
              to="/room"
              className={`${
                pathname === "/room"
                  ? "text-purple font-bold border-b-4   border-purple"
                  : ""
              } `}
            >
              Rooms
            </Link>
            <Link to="/chat" className="font-bold">
              Chats
            </Link>
          </div>
          <div className="px-[0.5rem]">
            <Link to="/create-room" className="text-purple">
              Create Room
            </Link>
          </div>
        </div>
        <div className=" py-[2rem] px-[1rem] bg-[#F2F6FA] flex-1 flex flex-col gap-5 h-full">
          <div className="w-full h-12 bg-white  border border-[#E1E8F1] rounded-full outline-none flx-row justify-between px-3 ">
            <SearchIcon className=" text-greyText text-lg" />
            <input
              type="text"
              placeholder="Search Chat ( Coming Soon )"
              className=" px-3  flex-1 font-normal bg-white"
              disabled
            />
            <TuneOutlinedIcon className=" text-greyText text-sm" />
          </div>
        
          <div className="flex flex-col gap-5">
            {rooms &&
              rooms.length > 0 &&
              rooms.map((item: any) => {
                const message = item.messages[item.messages.length - 1];
                return (
                  <div className="flx-row  rounded-lg group  bg-purple shadow-md h-full ">
                    <div className="px-1 py-3 hidden group-hover:block">
                      <DeleteOutlineOutlinedIcon
                        className="text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteChat(item.id);
                        }}
                      />
                    </div>

                    <div className="bg-white flex-1 flx-row gap-5 rounded-lg h-full p-3 group-hover:translate-x-2">
                      <div className="flx-row flex-wrap gap-2 w-10 ">
                        {item.users.map((user: any) => {
                          return (
                            <Avatar
                              alt="Remy Sharp"
                              src={user.profilePic}
                              sx={{ width: 16, height: 16 }}
                            />
                          );
                        })}
                        {/* <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 16, height: 16 }}
                      />
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 16, height: 16 }}
                      />
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 16, height: 16 }}
                      />
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 16, height: 16 }}
                      /> */}
                      </div>
                      <div
                        className="flex-1 flex flex-col gap-1 cursor-pointer"
                        onClick={() => {
                          navigate(`/room/${item.id}`);
                        }}
                      >
                        <div className="text-darkgrey text-sm flx-row justify-between">
                          <p className="font-bold text-md">{item?.name}</p>
                          <p className="text-xs">Yesterday</p>
                        </div>
                        <div className="text-darkgrey text-sm flx-row justify-between">
                          <p>
                            {message && message.type == "text"
                              ? message.content
                              : "Image"}
                          </p>
                          {unreadCounts[item.id] > 0 && (
                            <button className="rounded-full w-5 h-5 bg-[#01BCB0] text-xs text-white">
                              {unreadCounts[item.id]}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Room;
