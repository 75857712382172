import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // Import axios

interface LeaderboardParticipant {
  userId: string;
  name: string;
  score: number;
  timeTaken: number;
  createdAt: string;
  image: string;
}
const Leaderboard = () => {
  const navigate = useNavigate();
  const { id: tournamentId } = useParams<{ id: string }>();
  const [leaderboardData, setLeaderboardData] = useState<
    LeaderboardParticipant[]
  >([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(
          `${backendUrl}/tournaments/${tournamentId}/leaderboard`
        );
        if (response.status === 200) {
          setLeaderboardData(response.data.leaderboard);
        }
      } catch (error) {
        console.error("Failed to fetch leaderboard:", error);
      }
    };

    fetchLeaderboard();
  }, [tournamentId]);

  return (
    <div className="bg-lightestBlue min-h-screen font-outfit">
      <div className="px-4 py-8">
        <div className="flex flex-row items-center gap-x-3">
          <button
            onClick={() =>
              navigate("/quiz-list", { state: { activeTab: "Events" } })
            }
          >
            <img src="/icons/back-btn.svg" alt="Back" />
          </button>

          <p className="font-outfit text-primary text-[14px]">
            Tournament Leaderboard
          </p>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-white shadow rounded-lg p-4">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-lightGrey text-black text-left text-[14px]">
                <th className="py-2 px-4">Rank</th>
                <th className="py-2 px-4">Participant Name</th>
                <th className="py-2 px-4">Time Taken</th>
                <th className="py-2 px-4">Score</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((participant, index) => (
                <tr
                  key={participant.userId}
                  className={`${
                    index % 2 === 0 ? "bg-lightBlue" : "bg-white"
                  } text-text text-[14px]`}
                >
                  <td className="py-2 px-4">{index + 1}</td>
                  <td className="py-2 px-4 flex items-center gap-3">
                    <img
                      src={participant.image}
                      alt={participant.name}
                      className="w-8 h-8 rounded-lg object-cover"
                    />
                    <span>{participant.name}</span>
                  </td>
                  <td className="py-2 px-4">{participant.timeTaken} sec</td>
                  <td className="py-2 px-4">{participant.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
