import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MatchPopUp from "../../components/popup/MatchPopup";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { decodeUrlString, getProfile } from "../../utils/CommonFunctions";
import { handleFindMatch } from "../../services/matchService";

const MatchPage = () => {
  const { pathname } = useLocation();
  const [openForm, setOpenForm] = React.useState(false);
  const [collegeName, setCollegeName] = useState<any>("");
  const [genderStatus, setgenderStatus] = useState<any>("");
  const [interest, setinterest] = useState<any>("");
  const [profileData, setProfileData] = useState<any>("");
  const [matchedUsers, setMatchedUsers] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const pathParts = pathname.split("/"); // Split pathname by '/'
    console.log('ddsd',pathParts);
    
    setinterest(pathParts[2]); // Get the topic
    setgenderStatus(pathParts[4]);
    setCollegeName(decodeUrlString(pathParts[3]));
  }, [pathname]);

  useEffect(() => {
    getProfile(setProfileData);
  }, [collegeName]);

  useEffect(() => {
    let interestArray = [];
    let genderArray: any[] = [];
    if (profileData.interests) {
      interestArray = profileData.interests;
    }
    interestArray.push(interest);
    if (genderStatus === "0") {
      genderArray.push("Male");
    } else if (genderStatus === "1") {
      genderArray.push("Female");
    } else if (genderStatus === "2") {
      genderArray.push("Male");
      genderArray.push("Female");
    }
    let payload = {
      collegeNames: [`${collegeName}`],
      interests: interestArray,
      genders: genderArray,
    };
    console.log(payload);
    handleFindMatch(payload, setMatchedUsers);
  }, [profileData]);

  useEffect(() => {
    console.log(matchedUsers);
  }, [matchedUsers]);

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="py-[0.5rem]  text-md flex flex-col gap-5">
        <div className=" py-[2rem] px-[1rem] z-[-1] flex-1 flex flex-col gap-5 h-screen -translate-y-5 font-roboto">
          <div className="flex items-center flex-col gap-3">
            <p className="text-[#7A54F0] text-2xl ">Congrats</p>
            <p className="font-semibold text-darkgrey">
              Your Study mate is here
            </p>
          </div>
          {matchedUsers && matchedUsers.length > 0 && (
            <>
              {matchedUsers.map((user: any) => (
                <div className="flex bg-white rounded-[24px] shadow-default px-4 py-4 border-[#C3B7F9] border-[0.3px] justify-between items-center ">
                  <div className="flex items-center gap-2">
                    <div>
                      <img
                        src={user?.image}
                        className="max-h-12 max-w-12 rounded-xl"
                      />
                    </div>
                    <div>
                      <p className="font-medium text-[14px] leading-[20px] mt-1">
                        {user?.name}
                      </p>
                      <p className="font-light text-[12px] text-[#75737B] mt-1">
                        {user?.yearOfStudy}
                      </p>
                      <p className="font-light text-[12px] text-[#75737B] mt-1">
                        {user?.collegeName}
                      </p>
                    </div>
                  </div>
                  <div className="gap-4 flex">
                    <div>
                      <button>
                        <div className="flex bg-primary w-11 justify-center h-9 items-center rounded-xl">
                          <img src="/icons/tick.svg" />
                        </div>
                      </button>
                    </div>
                    <div>
                      <button className="flex items-center justify-center border-primary border-2 w-11 h-9 rounded-xl">
                        <img src="/icons/cancel.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          <div className="flex flex-col items-center gap-3">
            {/* <div className="flex flex-col gap-5 mt-10">
              <div
                className="bg-purpleGradient p-3 rounded-lg text-white w-[300px] flex items-center justify-center cursor-pointer"
                onClick={() => {
                  navigate(`/chat/${pathname.split("/")[2]}`);
                }}
              >
                Chat
              </div>
              <button
                className="bg-greenGradient p-3 rounded-lg text-white w-[300px] flex items-center justify-center "
                onClick={() => setOpenForm(!openForm)}
              >
                Search Again
              </button>
            </div> */}
            <p className="text-xs text-center">
              This match isn’t just about studies—it's about building a
              connection. Share your academic goals, support each other through
              the rigors of med school, and have fun along the way!
            </p>
          </div>
        </div>
        {openForm && (
          <MatchPopUp setOpenForm={setOpenForm} openForm={openForm} />
        )}
      </div>
    </>
  );
};

export default MatchPage;
