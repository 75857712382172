import { useState } from "react";
import ActionButton from "../components/ActionButton";
import FormInput from "../components/FormInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Register = () => {
  const navigate = useNavigate();
  const [registerData, setRegisterData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleRegisterChange = (key: string, value: string) => {
    setRegisterData({
      ...registerData,
      [key]: value,
    });
  };

  // Password strength validation
  const validatePassword = (password: string) => {
    const passwordStrengthRegex =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
    return passwordStrengthRegex.test(password);
  };

  // Phone number validation for exactly 10 digits
  const validatePhoneNumber = (phone: string) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    return phoneNumberRegex.test(phone);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegister = async () => {
    if (!registerData.name) {
      toast.error("Please enter your name.");
      return;
    }
    // Check email validation
    if (!validateEmail(registerData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    // Check phone number validity
    if (!validatePhoneNumber(registerData.phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    // Check password strength
    if (!validatePassword(registerData.password)) {
      toast.error(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character."
      );
      return;
    }
    console.log(registerData);

    try {
      navigate(
        `/otp/${encodeURIComponent(registerData.email)}/${
          registerData.name
        }/${registerData.password}/${registerData.phone}`
      );
    } catch (error) {
      console.log(error, "error");
      toast.error("Something went wrong!");
    }
  };

  const googleLogin = () => {
    try {
      window.location.href = `${BACKEND_URL}/auth/google`;
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen bg-cover bg-center font-outfit">
      <div className="mt-auto flex flex-grow flex-col w-full px-4 justify-center">
        <h4 className="text-text text-[20px] text-center">
          Create your account
        </h4>
        <div className="flex flex-col gap-y-6 mt-8">
          <FormInput
            onChange={(e) => handleRegisterChange("name", e.target.value)}
            placeholder="Enter your name"
            type="text"
            value={registerData.name}
          />
          <FormInput
            onChange={(e) => handleRegisterChange("email", e.target.value)}
            placeholder="Enter your email ID"
            type="email"
            value={registerData.email}
          />
          <FormInput
            onChange={(e) => handleRegisterChange("phone", e.target.value)}
            placeholder="Enter your phone number"
            type="text"
            value={registerData.phone}
          />

          <div className="relative">
            <FormInput
              onChange={(e) => handleRegisterChange("password", e.target.value)}
              placeholder="Enter a Password"
              type={showPassword ? "text" : "password"} // Toggle between text and password types
              value={registerData.password}
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <img src="/icons/eye-slash.svg" />
              ) : (
                <img src="/eye.svg" />
              )}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-2 mt-8">
          <ActionButton
            buttonClass=""
            buttonText="Sign up"
            onClick={() => handleRegister()}
          />
        </div>
        <div className="flex justify-evenly items-center mt-12">
          <svg
            width="90"
            height="1"
            viewBox="0 0 90 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="0.5" x2="90" y2="0.5" stroke="#75737B" />
          </svg>

          <span className="text-[14px] font-regular font-outfit text-text">
            or
          </span>
          <svg
            width="90"
            height="1"
            viewBox="0 0 90 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="0.5" x2="90" y2="0.5" stroke="#75737B" />
          </svg>
        </div>
        <button
          className="flex items-center gap-x-2 justify-center mt-2"
          onClick={googleLogin}
        >
          <img src="/icons/google-icon.webp" className="h-6" />
          <span className="text-text text-[14px] font-outfit">
            Continue with Google
          </span>
        </button>
      </div>
    </div>
  );
};

export default Register;
