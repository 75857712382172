import React from "react";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center h-screen w-screen justify-center gap-y-14 px-4 font-outfit">
      <Logo />
      <div className="flex flex-col gap-y-6 w-full">
        <button
          className="bg-primary h-10 text-white rounded-xl text-[14px]"
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </button>
        <button
          className="border-primary border-[1.5px] h-10 text-primaryclickable rounded-xl text-[14px]"
          onClick={() => {
            navigate("/register");
          }}
        >
          Signup
        </button>
      </div>
    </div>
  );
};

export default Auth;
