import { toast } from "react-toastify";
import userService from "./userService";
import { NavigateFunction } from "react-router-dom";

export async function submitProfilePic(file: File, setImageSrc: any) {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await userService.updateUser(formData);
    if (res?.message === "User details updated successfully") {
      toast.success("Profile photo updated successfully");
      setImageSrc(res?.user?.image?.url);
    } else {
      toast.error("Failed to update profile photo");
    }
  } catch (error) {
    toast.error("Something went wrong while uploading the image");
  }
}

export const handleUpdateProfile = async (
  profileData: any,
  navigate: NavigateFunction,
  setCollegemandatory: any,
  setYearOfStudymandatory: any
) => {
  if (!profileData.collegeName) {
    setCollegemandatory(true);
  } else {
    setCollegemandatory(false);
  }
  if (!profileData.yearOfStudy) {
    setYearOfStudymandatory(true);
  } else {
    setYearOfStudymandatory(false);
  }
  if (!profileData.yearOfStudy || !profileData.collegeName) return;
  try {
    const updatedData = {
      phone: profileData.phone,
      dob: profileData.dob,
      gender: profileData.gender,
      collegeName: profileData.collegeName,
      yearOfStudy: profileData.yearOfStudy,
      interests: profileData.interests,
    };
    const res = await userService.updateUser(updatedData);
    if (res?.message) {
      toast.success("Profile updated successfully");
      navigate("/");
    }
  } catch (error) {
    toast.error("Failed to update profile. Please try again.");
  }
};

export const handleRemoveInterest = (
  interest: string,
  setSelectedInterests: any
) => {
  setSelectedInterests((prev: any) =>
    prev.filter((item: any) => item !== interest)
  );
};

export const handleAddInterest = (
  customInterest: any,
  setCustomInterest: any,
  selectedInterests: any,
  setSelectedInterests: any
) => {
  if (customInterest && !selectedInterests.includes(customInterest)) {
    setSelectedInterests((prev: any) => [...prev, customInterest]);
    setCustomInterest(""); // Clear input after adding
  }
};
