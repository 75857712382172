import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MatchPopup from "./popup/MatchPopup";

const TestNav = () => {
  const location = useLocation();
  const [isMatchSheetOpen, setIsMatchSheetOpen] = useState(false); // State for the bottom sheet

  const Navitems = [
    {
      name: "Home",
      src: "/icons/Home-icon.svg",
      activeSrc: "/icons/Home-icon-active.svg",
      href: "/",
      srOnly: "Home",
    },
    {
      name: "Forum",
      src: "/icons/Forum-icon.svg",
      activeSrc: "/icons/Forum-icon-active.svg",
      href: "/forum",
      srOnly: "Forum",
    },
    {
      name: "Match",
      src: "/icons/Match-icon.svg",
      activeSrc: "/icons/Match-icon-active.svg",
      href: "#", 
      srOnly: "Match",
    },
    {
      name: "Quiz",
      src: "/icons/Quiz-icon.svg",
      activeSrc: "/icons/Quiz-icon-active.svg",
      href: "/quiz-list",
      srOnly: "Quiz",
    },
    {
      name: "Chat",
      src: "/icons/Chat-icon.svg",
      activeSrc: "/icons/Chat-icon-active.svg",
      href: "/chat",
      srOnly: "Chat",
    },
  ];

  const handleMatchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent default link behavior
    setIsMatchSheetOpen(true); // Open the bottom sheet
  };

  return (
    <>
      {/* Bottom Navigation Bar */}
      <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 inline-flex mx-auto justify-between bg-formfield w-full rounded-t-2xl py-1 ">
        {Navitems.map((item, index) => {
          const isActive = location.pathname === item.href;

          if (item.name === "Match") {
            // Render Match as a button
            return (
              <button
                key={index}
                className="relative inline-flex flex-col items-center text-xs font-medium py-2 px-6"
                onClick={handleMatchClick}
              >
                <div className="absolute bottom-2 p-5 rounded-full bg-formfield items-center">
                  <img
                    src={isMatchSheetOpen ? item.activeSrc : item.src}
                    alt={item.name}
                    className="w-6 h-6 ml-1"
                  />
                  <p className={`mt-1 ${isActive ? "text-primary": "text-text"}`}>{item.name}</p>
                </div>
                <span className="">{item.srOnly}</span>
              </button>
            );
          }

          // Render regular navigation items
          return (
            <Link
              key={index}
              to={item.href}
              className={`inline-flex flex-col items-center text-xs font-medium py-3 px-4 flex-grow ${
                isActive ? "text-white" : "text-primary"
              }`}
            >
              <div className={`${isActive ? "": " "}`}>
                <img
                  src={isActive ? item.activeSrc : item.src}
                  alt={item.name}
                  className="w-6 h-6"
                />
              </div>
              <p className={`mt-1 ${isActive ? "text-primary": "text-text"}`}>{item.name}</p>
              <span className="sr-only">{item.srOnly}</span>
            </Link>
          );
        })}
      </div>

      {/* Bottom Sheet */}
      {isMatchSheetOpen && (
        <MatchPopup setOpenForm={setIsMatchSheetOpen} openForm={isMatchSheetOpen} />
      )}
    </>
  );
};

export default TestNav;
