// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function OtpVerfiy() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [orginemail, setEmail] = useState("");
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(false);
  const [sent, setsent] = useState(false);
  const { mail, name, password, phone } = useParams();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // State to store the OTP digits

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Refs for the input boxes

  // Handle input change for each box
  const handleChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus the next input box
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace navigation
  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    console.log(mail, "name");
    setEmail(decodeURIComponent(mail));
  }, []);
  const sendOtp = async () => {
    setisloading(true); // Start loading indicator

    try {
      // Payload to be sent to the API
      const payload = {
        email: mail,
        name: name,
        password: password,
        phone: phone,
      };

      // Make the API call with the payload
      const res = await axios.post(`${BACKEND_URL}/auth/register`, payload);

      // Handle the response
      toast.success("OTP sent to your mail successfully!");
      setisloading(false);
      setsent(true);
    } catch (err) {
      console.log(err.response.data.message);
      setisloading(false);
    }
  };

  const resendOtp = async () => {
    console.log(decodeURIComponent(mail));

    try {
      const res = axios.post(`${BACKEND_URL}/v1/auth/send-otp?email=${mail}`);
      console.log(res, "res");
      res.then((data) => {
        toast.success("otp has been send again !");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOtp = async () => {
    setisloading(true);
    try {
      const newotp = otp.join("");
      const res = await axios.post(`${BACKEND_URL}/auth/verify-otp`, {
        otp: newotp,
        email: mail,
      });

      console.log(res, "res");
      if (res?.data.status === "success" && res?.data.token) {
        localStorage.setItem("accessToken", res?.data.token);
      }

      toast.success("Registered Successfully!");
      setsent(true);
      navigate("/");
    } catch (registerError) {
      console.error(registerError);
      toast.error(
        registerError.response?.data?.message || "Registration failed."
      );
    }
  };

  return (
    <div>
      <div className="flex flex-col h-screen justify-center font-outfit px-4">
        <div>
          {!sent ? (
            <div className="bg-white p-2 mt-5 rounded-2xl">
              <h4 className="text-text text-[20px] text-center">
                OTP Verification
              </h4>
              <div className="mt-8">
                <span className="text-[20px] font-outfit text-text">
                  Email Verification
                </span>
                <div className="text-[#878787] text-[14px]">
                  {" "}
                  The Verification code will be sent to:
                  <br /> {orginemail}
                </div>
              </div>

              <div>
                <div className="mt-auto flex flex-col gap-y-3 w-full py-8">
                  {!isloading ? (
                    <ActionButton
                      buttonClass=""
                      buttonText="Send Otp"
                      onClick={sendOtp}
                    />
                  ) : (
                    <div className="h-10 bg-primary p-4 items-center flex justify-between rounded-md">
                      {" "}
                      <LoaderCircle className="animate-spin text-white" />
                      <div className="text-white">Sending OTP... </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white p-2 mt-20 rounded-2xl font-outfit">
              <div className="px-4">
                <div>
                  <h4 className="text-text text-[20px] text-center">
                    Enter OTP
                  </h4>
                </div>
                <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-8">
                  {/* OTP Input Boxes */}
                  <div className="flex justify-center gap-x-4">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)} // Assign refs to input boxes
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        className="border-2 w-[54px] border-[#EAEAEA] rounded-xl p-2 text-center text-[28px]"
                        maxLength={1}
                        type="text"
                      />
                    ))}
                  </div>

                  {/* Resend OTP */}
                  <div
                    className="text-primary cursor-pointer items-center font-semibold text-center"
                    onClick={() => console.log("Resend OTP")}
                  >
                    <span className="text-[14px] font-normal text-[#878787]">
                      Did not Receive code ?{" "}
                    </span>
                    Resend
                  </div>

                  {/* Verify OTP Button */}
                  <div className="mt-12">
                    {!isloading ? (
                      <button
                        className="bg-primary h-10 w-full text-white font-outfit rounded-xl text-[14px] shadow-lg"
                        onClick={verifyOtp}
                      >
                        Verify
                      </button>
                    ) : (
                      <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
                        <LoaderCircle className="animate-spin text-white" />
                        <div className="text-white">Verifying OTP...</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OtpVerfiy;
