import { useEffect, useState } from "react";
import { api } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

interface ConversationProps {
  conversation: {
    _id: string;
    members: string[];
  };
  currentUser: User;
}

interface User {
  _id: string;
  name: string;
  image?: {
    url: string;
  };
  coins: number;
}

const Conversation: React.FC<ConversationProps> = ({
  conversation,
  currentUser,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [currentChat, setCurrentChat] = useState<string | null>(null);
  const [myId] = useState(currentUser);
  const navigate = useNavigate(); // Navigation hook

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== myId._id); // Get the friend's ID

    const getUser = async () => {
      try {
        if (!friendId) return;
        const res = await api.get(`/user/${friendId}`); // Fetch user details

        setUser(res.data.person); // Save the person object to state
      } catch (err) {
        console.error("Error fetching user:", err);
      }
    };

    getUser();
  }, [conversation, myId._id]);

  // Handle case when user data is not yet loaded
  if (!user) {
    return <p>Loading chats...</p>;
  }

  // Navigate to the message page
  const handleClick = async () => {
    try {
      // Fetch the time expiration flag from the backend
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/chat/${conversation._id}/first-message`
      );

      // Check if the response was successful
      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || "Unable to load conversation details.");
        return;
      }

      // Parse the response data
      const { isTimeExpired } = await response.json();

      // Check if the trial period has expired and if the user has sufficient coins
      if (isTimeExpired && myId?.coins < 10) {
        alert(
          "Your free trial period has expired. Please recharge to continue."
        );
        return; // Prevent further action if time expired and coins are insufficient
      }

      // Set the current chat and navigate to the chat page if conditions are met
      setCurrentChat(conversation._id);
      navigate(`/chat/${conversation._id}`, {
        state: { conversation, user, myId },
      });
    } catch (err) {
      console.error("Error in handleClick:", err);
      alert("An error occurred while processing your request.");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex rounded-[16px] shadow-default mb-4 border-[#C3B7F9] border-[0.3px] justify-between items-center"
    >
      <div className="flex items-center gap-2 p-4">
        <div>
          <img
            src={user?.image?.url || "https://via.placeholder.com/48"} // Fallback image
            alt={user?.name || "User"}
            className="h-12 w-12 rounded-[16px]"
          />
        </div>
        <div>
          <p className="font-medium text-[14px] leading-[20px]">{user?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
