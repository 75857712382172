import React from 'react'

const DuoUser = () => {
  return (
    <div className="px-4 font-outfit">
        {/* user header section */}
    <div className="">
        <div className="flex justify-center">
            <img
                src=""
                alt=""
                className="h-24 w-24 rounded-[16px]"
            />
        </div>
        <div className="text-center mt-5">
            <p className="text-text font-medium font-outfit text-[16px]">Username</p>
            <p className="font-light text-text text-[14px]">Profession</p>
        </div>
        <div className="flex mt-8 gap-6 w-full">
            <button
                className="bg-primary h-10 w-full font-outfit rounded-[12px] text-white"
            >
                + Connect
            </button>
            <button
                className="border-[1px] h-10 w-full border-primary text-primary rounded-[12px]"
            >
                Message
            </button>
        </div>
    </div>
    {/* User Interest and College */}
    <div className='mt-8'>
        <div>
            <p className='text-text text-[16px] mb-2.5'>College</p>
            <p className='text-text font-outfit text-[20px]'>KJ Somaiya Institute of Technology</p>
        </div>
        <div className='mt-6'>
            <p className='text-text text-[16px] mb-2'>Interest</p>
            <p className='max-w-max rounded-[12px] text-[14px] border-gray-600 border-[1px] p-2'>
                    Anatomy
            </p>
        </div>
    </div>
</div>

  )
}

export default DuoUser