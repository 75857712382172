import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MatchPopUp from "../components/popup/MatchPopup";
import {
  LogOut,
  X, // Close icon
} from "lucide-react";
const PrivacyPolicy = require("../assets/docs/Privacy&Policy.pdf");
const TermsAndConditions = require("../assets/docs/duocortex_t_and_c.pdf");

const SideBar = ({ profileData }: { profileData: any }) => {
  const [isOpen, setIsOpen] = useState(true); // Sidebar visibility state

  const array = [
    { name: "My Profile", url: "/profile", src: "/icons/profile.svg" },
    { name: "Global Forum", url: "/forum", src: "/icons/Forum-icon.svg" },
  ];

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("Logged out successfully");
    navigate("/auth");
  };

  const [openForm, setOpenForm] = React.useState(false);

  return (
    <>
      {/* Close Sidebar Button */}
      {isOpen && (
        <X
          size={24}
          className="absolute top-5 left-5 text-primary cursor-pointer"
          onClick={() => setIsOpen(false)} // Toggle visibility
        />
      )}

      {/* Sidebar */}
      {isOpen && (
        <>
          <section className="h-full bg-white text-text py-20 px-4 w-[300px] flex flex-col shadow-lg z-50">
            {profileData?.image?.url && (
              <>
                <div className="mb-4">
                  <img
                    className="max-h-16 max-w-16 rounded-2xl object-fill"
                    src={profileData?.image?.url}
                    alt=""
                  />
                </div>
              </>
            )}
            <span className="font-outfit font-medium text-[16px] mb-4">
              {profileData?.name}
            </span>
            <div className="flex flex-col gap-3">
            <hr />
            <div className="flex items-center justify-between">
              <div>
                <span className="font-outfit font-medium text-[14px]">
                  Duobalance
                </span>
                <div className="flex items-center">
                  <img src="/icons/duocoin.png" />
                  <span className="ml-1 font-outfit text-[20px] font-light">
                    {profileData?.coins}
                  </span>
                </div>
              </div>
              <div>
                <button>
                  <img src="/icons/Wallet.svg" />
                </button>
              </div>
            </div>
            <hr className="mb-4" />
            </div>
            {array.map((tab, index) => {
              return (
                <div key={index} className="flex items-center mb-4 gap-3">
                  <img src={tab.src} className="text-text" />
                  <Link
                    to={tab.url}
                    className="font-outfit text-[14px] text-text"
                  >
                    {tab.name}
                  </Link>
                </div>
              );
            })}
            <div className="flex items-center mb-4 gap-3">
              <img src={"/icons/privacy-policy.svg"} className="text-text" />
              <a
                href={TermsAndConditions}
                target="_blank"
                className="font-outfit text-[14px] text-text"
              >
                Terms & Conditions
              </a>
            </div>
            <div className="flex items-center mb-4 gap-3">
              <img
                src={"/icons/terms-and-conditions.svg"}
                className="text-text"
              />
              <a
                href={PrivacyPolicy}
                target="_blank"
                className="font-outfit text-[14px] text-text"
              >
                Privacy Policy
              </a>
            </div>
            <button
              className="text-[#6023D2] font-outfit h-8 w-[90%]  rounded-xl text-[14px] shadow-lg bottom-[150px] flex justify-center items-center border border-solid border-[#6023D2] absolute "
              onClick={handleLogout}
            >
              <LogOut size={18} className="text-primary" />
              <span className="">Logout</span>
            </button>
            {openForm && (
              <MatchPopUp setOpenForm={setOpenForm} openForm={openForm} />
            )}
          </section>
        </>
      )}
    </>
  );
};

export default SideBar;
