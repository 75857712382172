import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import {
  getQuizDetails,
  handleQuizDetailFetch,
  quizStart,
} from "../../services/quizService";
import { storeSingleDataInSession } from "../../utils/localStorageService";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import userService from "../../services/userService";
import { getProfile } from "../../utils/CommonFunctions";

const QuizDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [quizDetails, setQuizDetails] = useState<any>(null);
  const [profileData, setProfileData] = useState<any[]>([]);

  useEffect(() => {
    getProfile(setProfileData);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchQuizData = async () => {
      if (id) {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendUrl}/quizzes/quiz-detail/${id}`);
        const data = await response.json();
        setQuizDetails(data);
      }
      setLoading(false);
    };
    fetchQuizData();
  }, [id]);

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <PulseLoader color="#7A54F0" loading={loading} />
      </div>
    );
  }

  return (
    <>
      {quizDetails && (
        <div className="bg-lightestBlue min-h-screen font-outfit">
          <div className="px-4 py-8">
            <div className="flex flex-row items-center gap-x-3">
              <button onClick={() => navigate("/quiz-list")}>
                <img src="/icons/back-btn.svg" />
              </button>
              <p className="font-outfit text-primary text-[14px]">
                Quiz Details
              </p>
            </div>
          </div>
          <div className="px-4">
            <p className="text-[20px]">Brief explanation about the quiz</p>
            <div className="flex flex-col gap-6 mt-6 mb-10">
              <div className="flex gap-3">
                <div>
                  <img src="/icons/questions-icon.svg" />
                </div>
                <div>
                  <p className="text-[16px] text-left text-text">
                    {quizDetails.data.numberOfQuestions} Question's
                  </p>
                  <p className="font-roboto text-[14px] text-left text-text">
                    2 point's for each correct answer
                  </p>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <img src="/icons/time-icon-quiz.svg" />
                </div>
                <div>
                  <p className="font-roboto text-[16px] font-500 leading-[18.75px] text-left">
                    {quizDetails.data.duration} Minutes
                  </p>
                  <p className="font-roboto text-[14px] font-400 leading-[14.75px] text-left text-grey-400 text-textBlack">
                    Total duration of the quiz
                  </p>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <img src="/icons/duocoin-icon-quiz.svg" />
                </div>
                <div>
                  <p className="font-roboto text-[16px] font-500 leading-[18.75px] text-left">
                    Win {quizDetails.data.prize} coins
                  </p>
                  <p className="font-roboto text-[14px] font-400 leading-[14.75px] text-left text-textBlack">
                    Prize money rewarded after quiz
                  </p>
                </div>
              </div>
            </div>
            <p className="font-roboto text-text text-[16px] font-medium mb-[24px]">
              Before you begin the quiz, please reads the terms and conditions
              properly
            </p>
            <ul className="m-0 px-4 mb-6 list-disc flex flex-col gap-2 text-formfieldtext">
              <li className="text-[14px] text-left leading-[16px]">
                {quizDetails.reward} coins awarded only and only if participant
                answers all questions correctly
              </li>
              <li className="text-[14px] text-left leading-[16px]">
                The entry fee will be deducted from the wallet as soon as the
                participant starts the quiz
              </li>
              <li className="text-[14px] text-left leading-[16px]">
                Tap on options to select the correct answer
              </li>
              <li className="text-[14px] text-left leading-[16px]">
                Once the time has finished, the quiz will get submitted
                automatically
              </li>
              <li className="text-[14px] text-left leading-[16px]">
                Each participant can only attempt the quiz once
              </li>
            </ul>
            <div className="mt-10">
              <ActionButton
                buttonClass=""
                buttonText="Attempt Quiz"
                // onClick={() => {
                //   handleQuizDetailFetch(setLoading, id, navigate);
                // }}
                onClick={() => navigate(`/quiz-questions/${id}`)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizDetails;
