import { useLocation, useNavigate } from "react-router-dom";

const Result: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { results } = state || {};
  const score = results?.score || 0;
  const timeTaken = results?.timeTaken || 0; // Time taken in seconds
  const coinsEarned = 0; // Placeholder for now

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  return (
    <div className="bg-white min-h-screen font-outfit font-normal px-4 py-6 text-[#232424]">
      <div className="p-6">
      <button onClick={() => navigate("/quiz-list")}>
        <img src="/icons/back-btn.svg" />
      </button>
        <div className="flex justify-between mb-4 mt-4">
          <div className="text-lg">Results</div>
          <div className="text-lg font-bold">{""}</div>
        </div>
        <div className="flex justify-between mb-4">
          <div className="text-lg">Total Points Obtained:</div>
          <div className="text-lg">{score}</div>
        </div>

        <div className="flex justify-between mb-4">
          <div className="text-lg">Total Time Taken:</div>
          <div className="text-lg">{formatTime(timeTaken)}</div>
        </div>

        <div className="flex justify-between mb-4">
          <div className="text-lg">Coins Earned:</div>
          <div className="text-lg">{coinsEarned}</div>
        </div>

        <button
          className="mt-6 bg-primary text-white px-4 py-2 rounded-xl w-full"
          onClick={() => navigate("/quiz-list")}
        >
          Take Another Quiz
        </button>
      </div>
    </div>
  );
};

export default Result;
