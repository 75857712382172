const COLORS = {
  purple: "#7A54F0",
  lightPurple: "#C05AFF",
  secondary: "#42EBAB",
  lightSecondary: "#01BCB0",
  greyText: "#99A9D1",
  shadowPurple: "#613EEA",
  lightBlue: "#F2F6FA"
};

export default COLORS;