import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Auth from "./pages/Auth";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Forum from "./pages/Forum";
import Room from "./pages/Chat/Room";
import Chats from "./pages/Chat/Chats";
import CreateRoom from "./pages/Chat/CreateRoom";
import Chat from "./pages/Chat/Chat";
import GroupChat from "./pages/Chat/GroupChat";
import Otptest from "./pages/Otptest";
import Profile from "./pages/Profile";
import Kyc from "./pages/Kyc";
import Location from "./pages/Location";
import GoogleLogin from "./pages/GoogleLogin";
import Meet from "./components/Meeting";
import OtpVerfiy from "./pages/OtpVerfiy";
import QuizList from "./pages/quiz/QuizList";
import QuizDetails from "./pages/quiz/QuizDetails";
import QuestionList from "./pages/quiz/QuestionList";
import LeaderBoard from "./pages/quiz/LeaderBoard";
import Result from "./pages/quiz/Result";
import RedirectGroup from "./pages/RedirectGroup";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyCode from "./pages/VerifyCode";
import ResetPassword from "./pages/ResetPassword";
import MatchPage from "./pages/Match/MatchPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TournamentDetails from "./pages/quiz/TournamentDetails";
import TournamentQuestions from "./pages/quiz/TournamentQuestions";
import ResultTournament from "./pages/quiz/ResultTournament";
import BottomNavbar from "./layouts/BottomNavbar";
import Recharge from "./pages/Recharge";
import DuoUser from "./pages/DuoUser";

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  function handleWindowResize() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return isMobile ? (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/otptest" element={<Otptest />} />
          <Route path="/google-login" element={<GoogleLogin />} />
          <Route path="/register" element={<Register />} />{" "}
          <Route
            path="/otp/:mail/:name/:password/:gender"
            element={<OtpVerfiy />}
          />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/verify/code/:mail" element={<VerifyCode />} />
          <Route
            path="/reset/password/:mail/:otp"
            element={<ResetPassword />}
          />
          <Route element={<BottomNavbar />}>
            <Route path="/" element={<Home />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/quiz-list" element={<QuizList />} />
            <Route path="/quiz-details/:id" element={<QuizDetails />} />
            <Route path="/event-details/:id" element={<TournamentDetails />} />
            <Route path="/quiz-questions/:id" element={<QuestionList />} />
            <Route
              path="/tournament-questions/:id"
              element={<TournamentQuestions />}
            />
            <Route path="/leaderboard/:id" element={<LeaderBoard />} />
            <Route path="/result/quiz/:id" element={<Result />} />
            <Route
              path="/result/tournament/:id"
              element={<ResultTournament />}
            />
            {/* <Route path="/connect" element={<Match />} />{" "} */}
            <Route
              path="/matching/:topic/:college/:status"
              element={<MatchPage />}
            />
            <Route path="/duouser" element={<DuoUser/>}/>
            <Route path="/recharge" element={<Recharge />} />
            <Route path="/meet" element={<Meet />} />
            <Route path="/room" element={<Room />} />
            <Route path="/chat" element={<Chats />} />
            <Route path="/create-room" element={<CreateRoom />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/location" element={<Location />} />
          <Route path="/join/:token" element={<RedirectGroup />} />
          <Route path="/chat/:chatId" element={<Chat />} />
          <Route path="/room/:roomId" element={<GroupChat />} />
        </Routes>
      </Router>
    </>
  ) : (
    <div>Acess Duocortex on Mobile for Better Experience</div>
  );
}

export default App;
