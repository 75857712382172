import React from "react";


const WalletHeader = ({ profileData }: { profileData: any }) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="mb-1 font-outfit font-medium text-[16px]">
          Available Balance
        </p>
        <div className="flex items-center gap-1">
          <img src="/icons/duocoin.png" alt="wallet_icon" className="w-6 h-6" />
          <span className="text-text font-outfit font-light text-[24px]">
            {profileData?.coins}
          </span>
        </div>
        {/* <img src={WelletIcon} alt='wellet_icon' className='w-4 h-4' /> */}
      </div>

      <div>
        <button 
        
        className="px-[16px] py-2 bg-primary rounded-[16px] justify-center items-center">
          <img src="/icons/wallet-add.svg" />
        </button>
      </div>
    </div>
  );
};

export default WalletHeader;
