import { api } from "../config/apiConfig";

export const userPostApis = () => {
  const createPost = async (data: any) => {
    const res = await api.post("/posts/new", data);
    return res.data;
  };
  const getCurrPost = async (id: string) => {
    const res = await api.get(`/posts/post/${id}`);
    return res.data;
  };
  const makeComment = async (data: any) => {
    const res = await api.post(`/posts/${data.postId}/comments`, data.payload);
    data.setPostComments(res?.data?.data?.comments);
    console.log(res);
  };
  const makeReply = async (postId: any, payload: any, setPostComments: any) => {
    const res = await api.post(`/posts/${postId}/comments`, payload);
    setPostComments(res?.data?.data?.comments);
  };
  const repost = async (data: any) => {
    const res = await api.post(`/posts/repost/${data.postId}`);
    return res.data;
  };

  const connectFriend = async (payload: any) => {
    const res = await api.post(`/matches/request`, payload);
    return res.data;
  };
  const FriendStatus = async (friendId: string, setFriendStatus: any) => {
    const res = await api.get(`/user/status/${friendId}`);
    setFriendStatus(res?.data?.status);
  };
  return {
    createPost,
    getCurrPost,
    makeComment,
    connectFriend,
    repost,
    FriendStatus,
    makeReply,
  };
};
