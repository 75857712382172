import React, { useState, useEffect } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";
import { MdMyLocation } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import ActionButton from "../components/ActionButton";

const Location = () => {
  const navigate = useNavigate();
  const [ordinates, setOrdinates] = useState<any>({
    latitude: "",
    longitude: "",
  });
  const [address, setAddress] = useState("");
  const [token, setToken] = useState<string | null>(null);

  // Step 1: Fetch OAuth Token
  const fetchOAuthToken = async () => {
    const clientId = "cc04ba85-16b3-47d8-beb4-2a1c24029ccc";
    const clientSecret = "01P4vy8jyjS3Lnc9u1MFQAtN1zIW666z";
    const authUrl = "https://api.krutrim.ai/oauth/token"; // OAuth token endpoint

    const response = await fetch(authUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: "client_credentials",
      }),
    });

    const data = await response.json();
    setToken(data.access_token);
  };

  // Step 2: Get user's current location
  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((location) => {
      setOrdinates({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
    });
  };

  useEffect(() => {
    if (ordinates?.latitude && ordinates?.longitude && token) {
      const reverseGeocodeUrl = `https://api.krutrim.ai/reverse-geocoding?lat=${ordinates.latitude}&lng=${ordinates.longitude}`;

      fetch(reverseGeocodeUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddress(data.address);
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
        });
    }
  }, [ordinates, token]);

  useEffect(() => {
    // Fetch OAuth token on component mount
    fetchOAuthToken();
  }, []);

  return (
    <div className="flex flex-col min-h-screen my-8 mx-4">
      <div className="flex flex-row items-center justify-between gap-x-3">
        <IoIosArrowDropleft
          size={24}
          color={COLORS.purple}
          onClick={() => navigate("/interest")}
        />
        <Link to="/">
          <p className="font-inter text-lg text-purple">Skip</p>
        </Link>
      </div>
      <div className="my-5">
        <h4 className="font-inter text-xl text-center">Add your location</h4>
        <p className="text-greyText font-roboto font-medium text-center mt-2">
          Let the app locate you to provide the best results around you
        </p>
        <div className="flex flex-col gap-y-5 my-8">
          <FormInput
            placeholder="Current Location"
            onChange={() => {}}
            value={address || ""}
            type="text"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
            suffix={
              <MdMyLocation
                size={18}
                color={COLORS.greyText}
                onClick={getCurrentLocation}
              />
            }
          />
          {/* <FormInput
            placeholder="State"
            onChange={() => {}}
            value={""}
            type="text"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
            suffix={<CiSearch size={18} color={COLORS.greyText} />}
          /> */}
        </div>
        <div className="mt-8">
          <ActionButton
            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
            buttonText="Continue"
            onClick={() => navigate("/profile")}
          />
        </div>
      </div>
    </div>
  );
};

export default Location;
