// @ts-nocheck

import axios from "axios";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";

function VerifyCode() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // State for OTP input
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const { mail } = useParams();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Refs for the input boxes

  // Handle OTP input change
  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus the next input box
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace navigation
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const sendCode = async () => {
    setIsResendLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/reset-password`, {
        email: mail,
      });
      toast.success(res.data.message);
      setIsResendLoading(false);
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong");
      setIsResendLoading(false);
    }
  };

  const verifyCode = async () => {
    setIsVerifyLoading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/auth/verify-reset-otp`, {
        email: mail,
        otp: otp.join(""),
      });
      toast.success(res.data.message);
      setIsVerifyLoading(false);
      navigate(`/reset/password/${encodeURIComponent(mail)}/${encodeURIComponent(otp.join(""))}`);
    } catch (err) {
      toast.error(err.response?.data?.message || "Invalid OTP");
      setIsVerifyLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col px-4">
      <div className="flex flex-row items-center justify-between mt-6">
        <IoIosArrowDropleft
          size={34}
          color={COLORS.purple}
          onClick={() => navigate("/forgot/password")}
        />
      </div>
      <div className="flex flex-col items-center justify-center font-outfit">
        <div className="w-full mt-12 rounded-2xl">
          <div>
            <h4 className="text-text text-[20px] text-center">Change Password</h4>
            <div className="mt-auto flex flex-col w-full">
              {/* Modern OTP Input */}
              <div className="flex gap-x-4 mt-8 w-full">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)} // Assign refs
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="border-2 w-[50px] border-[#EAEAEA] rounded-xl p-2 text-center text-[28px]"
                    maxLength={1}
                    type="text"
                  />
                ))}
              </div>

              {/* Resend OTP */}
              {!isResendLoading ? (
                <div
                  onClick={sendCode}
                  className="text-primary cursor-pointer font-normal text-xs text-right"
                >
                  Resend
                </div>
              ) : (
                <div className="flex text-primary justify-end cursor-pointer font-normal text-xs text-right">
                  <LoaderCircle className="animate-spin text-primary text-xs" />
                  <div className="text-primary">Resending Code</div>
                </div>
              )}

              {/* Verify OTP Button */}
              {!isVerifyLoading ? (
                <button
                  className="bg-primary h-10 mt-8 text-white font-outfit rounded-xl text-[14px] shadow-lg"
                  onClick={verifyCode}
                >
                  Verify Code
                </button>
              ) : (
                <div className="p-4 flex justify-between font-outfit rounded-md bg-primary">
                  <LoaderCircle className="animate-spin text-white" />
                  <div className="text-white">Verifying Code</div>
                </div>
              )}
            </div>

            {/* Redirect to Login */}
            <div className="mt-20">
              <p className="text-text font-outfit font-normal text-[14px] text-center">
                Try logging into account?{" "}
                <Link
                  to="/login"
                  className="text-[#313035] text-[14px] font-medium"
                >
                  Login Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
