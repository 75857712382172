import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useNavigate } from "react-router-dom";
import { api } from "../../config/apiConfig";
import Conversation from "./Conversation";
import { getConversations, getProfile } from "../../utils/CommonFunctions";
import Navbar from "../../components/Navbar";

const Chats = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<any>({});
  const [conversations, setConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [filteredConversations, setFilteredConversations] = useState([]);

  useEffect(() => {
    getProfile(setProfileData);
  }, []);

  useEffect(() => {
    getConversations(profileData, setConversations);
  }, [profileData?._id]);

  useEffect(() => {
    // Filter conversations based on the search term
    const filtered = conversations.filter((c: { [key: string]: any }) => {
      const members = c.members.join(" ").toLowerCase();
      return members.includes(searchTerm.toLowerCase());
    });
    setFilteredConversations(filtered);
  }, [searchTerm, conversations]);

  return (
    <div className="text-[14px] flex flex-col min-h-screen">
      <Navbar />

      <div className="px-[1rem] flex-1 flex flex-col gap-5 h-full font-outfit mt-4">
        <div className="flex flex-col">
          <h1 className="text-left">My Inbox</h1>

          <div className="my-[24px]">
            {filteredConversations.map(
              (c: { _id: string; members: string[]; [key: string]: any }) => (
                <Conversation
                  key={c._id}
                  conversation={c}
                  currentUser={profileData}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
