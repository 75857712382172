import { useState } from "react";
import FormInput from "../components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authService";
import { toast } from "react-toastify";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [errorMessage, setErrorMessage] = useState(""); // State to track error message

  const handleLoginChange = (key: string, value: string) => {
    setLoginData({
      ...loginData,
      [key]: value,
    });
    setErrorMessage(""); // Clear error message when the user starts typing
  };

  const handleLogin = async () => {
    try {
      const res: any = await authService.login({
        email: loginData.email,
        password: loginData.password,
      });

      console.log(res, "response from API");
      if (res?.token) {
        localStorage.setItem("accessToken", res?.token);
        toast.success("Logged in successfully!");
        navigate("/");
      } else {
        setErrorMessage("Invalid credentials"); // Set error message
      }
    } catch (error: any) {
      console.log(error?.response?.data?.message || error, "error");
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        // Check if the API provides an error message for invalid credentials
        if (error?.response?.status === 401) {
          setErrorMessage("Invalid credentials"); // Display error message for invalid credentials
        } else {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  const googleLogin = () => {
    try {
      window.location.href = `${BACKEND_URL}/auth/google`;
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen bg-cover bg-center">
      <div className="flex flex-grow flex-col w-full px-4 justify-center">
        <h4 className="text-text font-medium font-outfit text-[36px] text-center">
          Welcome back!
        </h4>
        <h4 className="text-text font-regular font-outfit text-[20px] text-center mb-12">
          We’re glad to see you
        </h4>
        <div className="flex flex-col gap-y-6">
          <FormInput
            onChange={(e) => handleLoginChange("email", e.target.value)}
            placeholder="Enter email"
            type="email"
            value={loginData.email}
          />
          <div className="relative">
            <FormInput
              onChange={(e) => handleLoginChange("password", e.target.value)}
              placeholder="Enter password"
              type={showPassword ? "text" : "password"} // Toggle password visibility
              value={loginData.password}
            />
            {/* Show/Hide button */}
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 transition-all" // Correct alignment
              onClick={() => setShowPassword(!showPassword)} // Toggle password visibility state
            >
              {showPassword ? (
                <img src="/icons/eye-slash.svg" />
              ) : (
                <img src="/eye.svg" />
              )}
            </button>
          </div>
        </div>
        <div className="justify-between flex mt-1">
          {/* Error message */}
          {errorMessage ? (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          ) : (
            <div className="flex-grow"></div> // Placeholder to maintain spacing
          )}
          <Link
            to={"/forgot/password"}
            className="text-[#313035] text-[12px] text-end"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="flex flex-col mt-6">
          <button
            className="bg-primary h-10 text-white font-outfit rounded-xl text-[14px] shadow-lg"
            onClick={handleLogin}
          >
            Login
          </button>
          <div className="flex justify-evenly items-center mt-12">
            <svg
              width="90"
              height="1"
              viewBox="0 0 90 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="90" y2="0.5" stroke="#75737B" />
            </svg>

            <span className="text-[14px] font-regular font-outfit text-text">
              or Login with
            </span>
            <svg
              width="90"
              height="1"
              viewBox="0 0 90 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="90" y2="0.5" stroke="#75737B" />
            </svg>
          </div>
          <button
            className="flex items-center gap-x-2 justify-center mt-2"
            onClick={googleLogin}
          >
            <img src="/icons/google-icon.webp" className="h-6" />
            <span className="text-text text-[14px] font-outfit">
              Google account
            </span>
          </button>

          <div className="mt-20">
            <p className="text-text font-outfit font-normal text-[14px] text-center">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="text-[#313035] text-[14px] font-medium"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
