import { NavigateFunction } from "react-router-dom";
import { api } from "../config/apiConfig";

export const userMatchApi = () => {
  const MatchUsers = async () => {
    const res = await api.get("/user/connect");
    return res.data;
  };

  return { MatchUsers };
};
export const handleMatchPress = async (
  interest: any,
  setInterestError: any,
  male: any,
  female: any,
  collegeName: any,
  navigate: NavigateFunction,
  setOpenForm: any
) => {
  if (!interest) {
    setInterestError(true);
    return;
  }
  let status = 0;
  if (male) status = 0;
  if (female) status = 1;
  if (male && female) status = 2;

  setOpenForm(false);
  if (collegeName.label) {
    navigate(`/matching/${interest}/${collegeName.label}/${status}`);
  } else {
    navigate(`/matching/${interest}/none/${status}`);
  }
};
export const handleFindMatch = async (payload: any, setMatchedUsers: any) => {
  const res = await api.post("/user/match-users", payload);
  console.log(res);
  if (res?.data) {
    setMatchedUsers(res.data?.data);
  }
};
