import React, { useEffect, useState } from "react";
import { Avatar, Button } from "@mui/material";
import Post from "../Post";
import { useForum } from "../../utils/Forum";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { getInstagramTimeFormat } from "../../utils/CommonFunctions";
import FormInput from "../FormInput";

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  width: "100%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px 20px 0px 0px",
};

type PopType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currPost: any;
};

const PostPop = ({
  open,
  setOpen,
  currPost,
  profileData,
}: PopType & { profileData: any }) => {
  const [comment, setComment] = useState("");
  const [postComments, setPostComments] = useState(currPost?.comments);
  const { handleComment, handleConnectFriend, FriendStatus, makeReply } =
    useForum();
  const [friendStatus, setFriendStatus] = useState();
  const [enableDisableConnect, setenableDisableConnect] = useState(false);

  // State to track active reply inputs per comment
  const [activeReply, setActiveReply] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (currPost?.repost) {
      FriendStatus(currPost?.repostCreator?._id, setFriendStatus);
    } else {
      FriendStatus(currPost?.createdBy?._id, setFriendStatus);
    }
    console.log("status", friendStatus);
  }, []);

  useEffect(() => {
    if (friendStatus === "Not Sent") {
      setenableDisableConnect(false);
    } else if (
      friendStatus === "Pending" ||
      friendStatus === "Accepted" ||
      friendStatus === "Rejected"
    ) {
      setenableDisableConnect(true);
    }
  }, [friendStatus]);

  // Placeholder for replying to a specific comment
  const handleReplyToComment = async (
    parentCommentId: string,
    text: string
  ) => {
    let payload = {
      parentCommentId: parentCommentId,
      text: text,
    };
    const res = await makeReply(currPost?._id, payload, setPostComments);
    console.log(res);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex flex-col">
          <div className="flx-row justify-between sticky top-0 bg-white rounded-xl px-5 pt-5 mb-4">
            {(currPost?.repostCreator?._id === profileData?._id &&
              currPost.repost) ||
            (currPost?.createdBy?._id === profileData?._id &&
              !currPost.repost) ? (
              <></>
            ) : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  className="h-6 font-semibold"
                  onClick={() => {
                    if (currPost?.repost) {
                      handleConnectFriend(
                        { receiverId: currPost?.repostCreator?._id },
                        currPost,
                        setFriendStatus
                      );
                    } else {
                      handleConnectFriend(
                        { receiverId: currPost?.createdBy?._id },
                        currPost,
                        setFriendStatus
                      );
                    }
                  }}
                  disabled={enableDisableConnect}
                >
                  {friendStatus === "Pending" && <>Request Pending</>}
                  {friendStatus === "Not Sent" && <>Connect</>}
                  {friendStatus === "Accepted" && <>Friends</>}
                  {friendStatus === "Rejected" && <>Rejected</>}
                </Button>
              </>
            )}
          </div>
          <div className="pb-4 px-2 overflow-y-auto no-scroll flex flex-col gap-5">
            <Post profileData={profileData} item={currPost} />
            <div className="px-4">
              {/* <div>
                <Avatar
                  alt="Profile"
                  src={profileData?.image?.url || "/static/images/avatar/1.jpg"}
                  sx={{ width: 29, height: 29 }}
                  className="translate-y-3"
                />
              </div> */}
              <div className="flex flex-col">
                <div className="flex flex-row gap-2 items-center">
                  <input
                    type="text"
                    placeholder="Add a comment"
                    className="w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px] text-formfieldtext placeholder:text-[#525058] border-[1px] border-primary"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      handleComment(currPost?._id, comment, setPostComments);
                      setComment("");
                    }}
                    src="/icons/sendMessage.svg"
                    className="h-6 w-6"
                  />
                
                </div>
                {/* Comments and Replies */}
                <div className="flex-col flex gap-2 mt-4">
                  {postComments.map((item: any) => (
                    <div
                      className="flex flex-col gap-2 p-2"
                      key={item?._id}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex justify-center items-center gap-2">
                          <img alt="User" src={item?.user?.image?.url} className="w-8 h-8 rounded-lg" />
                          <div className="flex flex-col">
                            <p className="text-text text-[14px] font-medium">
                              {item?.user?.name}
                            </p>
                            <p className="text-[14px]">{item?.text}</p>
                          </div>
                        </div>
                        <p className="text-text text-[12px]">{getInstagramTimeFormat(item?.createdAt)}</p>
                      </div>
                      <button
                        className="text-xs font-normal text-primary w-fit"
                        onClick={() =>
                          setActiveReply((prev) => ({
                            ...prev,
                            [item?._id]: "",
                          }))
                        }
                      >
                        Reply
                      </button>
                      {activeReply[item?._id] !== undefined && (
                        <div className="flex flex-row gap-3 mt-2">
                          <input
                            type="text"
                            placeholder="Reply..."
                            className="w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px] text-formfieldtext placeholder:text-[#525058] border-[1px] border-primary"
                            value={activeReply[item?._id]}
                            onChange={(e) =>
                              setActiveReply((prev) => ({
                                ...prev,
                                [item?._id]: e.target.value,
                              }))
                            }
                          />
                          <button
                            className="text-sm font-medium text-primary"
                            onClick={() => {
                              handleReplyToComment(
                                item?._id,
                                activeReply[item?._id]
                              );
                              setActiveReply((prev) => ({
                                ...prev,
                                [item?._id]: "",
                              }));
                            }}
                          >
                            Reply
                          </button>
                        </div>
                      )}
                      {/* Existing Replies */}
                      {item?.replies &&
                        item?.replies.map((reply: any) => (
                          <div
                            className="flex flex-col gap-4 py-1 ml-5"
                            key={reply?._id}
                          >
                            <div className="flex items-center justify-between">
                              {/* <div className=""> */}
                              <div className="flex justify-center items-center gap-2 ">
                                <img
                                  alt="Reply User"
                                  src={reply?.user?.image?.url}
                                  className="h-8 w-8 rounded-[8px]"
                                />
                                <div className="flex flex-col">
                                  <p className="text-text text-[14px] font-medium">
                                    {reply?.user?.name}
                                  </p>
                                  <p className="text-[14px]">{reply?.text}</p>
                                </div>
                              </div>
                              <p className="text-[12px]">{getInstagramTimeFormat(reply?.createdAt)}</p>

                              {/* </div> */}
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PostPop;
