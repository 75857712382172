import { useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  yearsOfStudy,
  colleges,
  predefinedInterests,
} from "../constants/Strings";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleLogout, getProfile } from "../utils/CommonFunctions";
import {
  handleAddInterest,
  handleRemoveInterest,
  handleUpdateProfile,
  submitProfilePic,
} from "../services/ProfileService";

const Profile = () => {
  const navigate = useNavigate();
  const [selectedInterests, setSelectedInterests] = useState<any[]>([]); // State for selected interests
  const [customInterest, setCustomInterest] = useState(""); // State for custom interest input
  const [profileData, setProfileData] = useState<any>();
  const [imageSrc, setImageSrc] = useState("");
  const [collegemandatory, setCollegemandatory] = useState<boolean>(false);
  const [yearOfStudymandatory, setYearOfStudymandatory] =
    useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    getProfile(setProfileData);
  }, []);
  useEffect(() => {
    if (profileData?.interests) setSelectedInterests(profileData.interests);
  }, [profileData]);
  useEffect(() => {
    setProfileData((prevState: any) => ({
      ...prevState,
      interests: selectedInterests,
    }));
  }, [selectedInterests]);
  const handleFileChange = (e: any) => {
    if (e.target.files) {
      submitProfilePic(e.target.files[0], setImageSrc);
    }
  };

  return (
    <div className="flex flex-col h-screen w-screen font-outfit">
      <div className="flex flex-col px-4 py-8 gap-y-8">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-x-3">
            <button onClick={() => navigate("/")}>
              <img src="/icons/back-btn.svg" />
            </button>
            <p className="font-outfit text-primary font-normal text-[14px]">
              Profile Page
            </p>
          </div>
          <button
            className="bg-primary px-6 h-[36px] rounded-[16px] shadow-default"
            onClick={() => handleLogout(navigate)}
          >
            <p className="font-outfit text-white text-xs">Log Out</p>
          </button>
        </div>

        {/* Profile Image Section */}
        <div className="h-36 flex flex-col items-center justify-center">
          <div className="relative">
            {profileData?.image?.url ? (
              <img
                src={imageSrc}
                className="rounded-[16px] w-[100px] h-[100px] border-primary border-2 object-fill"
              />
            ) : (
              <FaUserCircle size={132} color="white" />
            )}
            <div className="bg-white h-6 w-6 rounded-full flex items-center justify-center absolute top-2 -right-1">
              <MdModeEdit
                size={12}
                color="blue"
                onClick={() => inputRef.current?.click()}
              />
              <input
                type="file"
                className="hidden"
                ref={inputRef}
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
          <p className="text-text font-medium text-[16px] mt-6">
            {profileData?.name}
          </p>
          <p className="text-text font-light text-[14px]">
            {profileData?.email}
          </p>
        </div>
      </div>

      {/* Account Settings Section */}
      <div className="px-4 py-4 flex flex-col gap-y-4 text-darkgrey">
        <h4 className="font-outfit font-medium text-sm">Account Settings</h4>

        <div className="flex flex-col gap-y-2 w-full">
          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center">
              <p className="text-lightgrey text-sm">Phone Number:</p>
              <input
                type="tel"
                value={profileData?.phone || ""}
                onChange={(e) =>
                  setProfileData({ ...profileData, phone: e.target.value })
                }
                className="text-formfieldtext font-medium text-sm ml-2 w-full focus:outline-none focus:ring-0"
              />
            </div>
          </div>

          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center gap-12">
              <p className="text-lightgrey text-sm">DOB:</p>
              <DatePicker
                selected={profileData?.dob}
                onChange={(date: Date | null) =>
                  setProfileData({ ...profileData, dob: date })
                }
                dateFormat="yyyy-MM-dd"
                className="text-formfieldtext font-medium text-sm ml-2 w-full focus:outline-none focus:ring-0"
              />
            </div>
          </div>

          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center gap-6">
              <p className="text-lightgrey text-sm">Gender:</p>
              <Select
                options={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                  { label: "Others", value: "Others" },
                ]}
                value={{
                  label: profileData?.gender,
                  value: profileData?.gender,
                }}
                onChange={(option) =>
                  setProfileData({ ...profileData, gender: option?.value })
                }
                className="text-formfieldtext font-medium text-sm ml-2 w-full"
              />
            </div>
          </div>

          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center gap-6">
              <p className="text-lightgrey text-sm">College:</p>
              <Select
                options={colleges}
                value={
                  colleges.find(
                    (college) => college.label === profileData?.collegeName
                  ) || null
                }
                onChange={(option) => {
                  setProfileData({
                    ...profileData,
                    collegeName: option?.label,
                  });
                  setCollegemandatory(false);
                }}
                className="text-formfieldtext font-medium text-sm ml-2 w-full focus:outline-none focus:ring-0"
              />
            </div>
          </div>
          {collegemandatory && (
            <>
              <p className="text-red-500">College Name is mandatory</p>
            </>
          )}
          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center">
              <p className="text-lightgrey text-sm">Year Of Study:</p>
              <Select
                options={yearsOfStudy}
                value={
                  yearsOfStudy.find(
                    (year) => year.value === profileData?.yearOfStudy
                  ) || null
                }
                onChange={(option) => {
                  setProfileData({
                    ...profileData,
                    yearOfStudy: option?.value,
                  });
                  setYearOfStudymandatory(false);
                }}
                className="text-formfieldtext font-medium text-sm ml-2 w-full focus:outline-none focus:ring-0"
              />
            </div>
          </div>
          {yearOfStudymandatory && (
            <>
              <p className="text-red-500">Year Of Study is mandatory</p>
            </>
          )}
          <div className="border-[1px] border-primary px-2 py-2 rounded-[16px] w-full">
            <div className="flex flex-row items-center gap-6">
              <p className="text-lightgrey text-sm">Interests:</p>
              <div className="flex flex-wrap gap-2">
                {/* Predefined Interests */}
                {predefinedInterests.map((interest: any) => (
                  <button
                    key={interest}
                    className="bg-lightgrey text-xs px-4 py-2 rounded-full"
                    onClick={() =>
                      setSelectedInterests((prev) => [...prev, interest])
                    }
                  >
                    {interest}
                  </button>
                ))}
                {/* Custom Interest Input */}
                <input
                  type="text"
                  value={customInterest}
                  onChange={(e) => setCustomInterest(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" &&
                    handleAddInterest(
                      customInterest,
                      setCustomInterest,
                      selectedInterests,
                      setSelectedInterests
                    )
                  }
                  placeholder="Type your interest"
                  className="text-formfieldtext font-medium text-sm ml-2 w-full focus:outline-none focus:ring-0"
                />
                <button
                  onClick={() => {
                    handleAddInterest(
                      customInterest,
                      setCustomInterest,
                      selectedInterests,
                      setSelectedInterests
                    );
                  }}
                  className="text-primary"
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {/* Display selected interests */}
              {selectedInterests.map((interest, index) => (
                <span
                  key={index}
                  className="bg-primary text-white text-xs px-4 py-2 rounded-full flex items-center gap-2"
                >
                  {interest}
                  <button
                    onClick={() =>
                      handleRemoveInterest(interest, setSelectedInterests)
                    }
                    className="text-white text-sm"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Save Changes Button (Centered) */}
      <div className="px-4 py-4 flex justify-center">
        <button
          className="h-[36px] border-2 bg-primary rounded-[16px] text-white font-medium mt-6 shadow-default w-full"
          onClick={() =>
            handleUpdateProfile(
              profileData,
              navigate,
              setCollegemandatory,
              setYearOfStudymandatory
            )
          }
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default Profile;
