import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForum } from "../../utils/Forum";
import { useDropzone } from "react-dropzone";

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  width: "100%",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px 20px 0px 0px",
};

type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const PostInputPop = ({ openForm, setOpenForm }: PopType) => {
  const {
    formInputSource,
    handleCreatePost,
    image,
    description,
    setDescription,
    setImage,
  } = useForum();

  // Handle image drop or file selection
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0]); // Set the first selected image
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: ".jpg,.jpeg,.png", // Accept only image files
    maxSize: 5 * 1024 * 1024, // Limit file size to 5 MB
  });

  return (
    <div>
      <Modal
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex flex-col gap-5 relative">
          <div className="flex justify-center py-2">
            <div
              className="w-12 h-1 rounded-full cursor-pointer"
              onClick={() => setOpenForm(false)}
            ></div>
          </div>
          <div className="flx-row justify-between sticky top-0 bg-white rounded-xl px-5 pt-5">
            <p className="font-semibold">New Post</p>
          </div>
          <div className="p-5 overflow-y-auto no-scroll flex flex-col gap-5">
            {formInputSource.map((item, id) => (
              <input
                key={id}
                {...item}
                id="outlined-basic"
                required
                className="w-full border-b-2 p-2 outline-none"
              />
            ))}
            <textarea
              className="w-full border-b-2 p-2 outline-none"
              rows={4}
              cols={50}
              placeholder="What's on your mind?"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {/* Image Upload using Dropzone */}
            <div
              {...getRootProps()}
              className="border-2 border-dashed p-4 rounded-lg text-center cursor-pointer"
            >
              <input
                {...getInputProps()}
                className="border p-2 rounded"
                required
              />
              <p>
                {image ? image.name : "Drag & Drop or Click to Upload Image"}
              </p>
            </div>

            {/* Post Button */}
            <button
              className="bg-primary h-10 text-white font-outfit rounded-xl text-[14px] shadow-lg absolute bottom-[24px] left-0 right-0 mx-5"
              onClick={() => {
                handleCreatePost(); // Call the hook's method to create a post
              }}
            >
              POST
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PostInputPop;
