
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TestNav from "../components/TestNav"
import { useEffect } from "react";
import { isAuth } from "../utils/CommonFunctions";

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(()=>{
    isAuth(navigate)
  },[location])
  return (
    <div className="">
      <Outlet/>
      <TestNav/>
    </div>
  );
};

export default BottomNavbar;
