import { NavigateFunction } from "react-router-dom";
import userService from "../services/userService";
import { api } from "../config/apiConfig";
import { toast } from "react-toastify";

export const getInstagramTimeFormat = (createdAt: string | Date) => {
  const date = typeof createdAt === "string" ? new Date(createdAt) : createdAt;
  const now = new Date();
  const diff = Math.floor((now.getTime() - date.getTime()) / 1000); // Difference in seconds

  if (diff < 60) return "Just now"; // Less than 1 minute
  if (diff < 3600) return `${Math.floor(diff / 60)}m ago`; // Less than 1 hour
  if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`; // Less than 1 day
  if (diff < 604800) return `${Math.floor(diff / 86400)}d ago`; // Less than 1 week

  // For older dates, return a formatted date (e.g., Dec 15, 2024)
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const decodeUrlString = (encodedString: string): string => {
  try {
    return decodeURIComponent(encodedString);
  } catch (error) {
    console.error("Error decoding URL string:", error);
    return encodedString; // Return the original string if decoding fails
  }
};

export const getProfile = async (setProfileData: any) => {
  try {
    const res = await userService.getProfile();
    setProfileData(res);
  } catch (error) {
    console.log(error, "error");
  }
};

export const isAuth = async (navigate: NavigateFunction) => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    navigate("/login");
    toast.error("Please login");
    return;
  }
  try {
    // Make API call
    const response = await api.get("/user/user-details");
    if (response?.status !== 200) {
      localStorage.removeItem("accessToken");
      navigate("/login");
      toast.error("Please login again");
    }
    if (response?.status === 200) {
      if (!response?.data?.yearOfStudy || !response?.data?.collegeName) {
        navigate("/profile");
        toast.warn("Please complete your profile");
        console.log(response?.data);
      }
    }
  } catch (err) {
    localStorage.removeItem("accessToken");
    navigate("/login");
    toast.error("Please login again");
    console.log(err);
  }
};
export const handleLogout = (navigate: NavigateFunction) => {
  localStorage.removeItem("accessToken");
  toast.success("Logged out successfully");
  navigate("/auth");
};
export const fetchPendingRequests = async (setpendingRequests: any) => {
  try {
    // Make API call
    const response = await api.get("/matches/pending-requests");
    setpendingRequests(response?.data?.pendingRequests);
  } catch (err) {
    console.log(err);
  }
};
export const acceptFriendRequest = async (payload: any) => {
  try {
    const response = await api.post("/matches/accept", payload);
    toast.success(response?.data?.message);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};
export const rejectFriendRequest = async (payload: any) => {
  try {
    const response = await api.post("/matches/reject", payload);
    toast.success(response?.data?.message);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const getConversations = async (
  profileData: any,
  setConversations: any
) => {
  try {
    const res = await api.get("/chat/" + profileData?._id);

    setConversations(res.data);
  } catch (err) {
    console.log(err);
  }
};
