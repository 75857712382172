import { LoaderCircle } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import ActionButton from "../components/ActionButton";

const Otptest = () => {
  const [isloading, setisloading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]); // State to store the OTP digits

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Refs for the input boxes

  // Handle input change for each box
  const handleChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus the next input box
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace navigation
  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const verifyOtp = () => {
    // Simulate verification
    setisloading(true);
    setTimeout(() => {
      setisloading(false);
      console.log("OTP verified:", otp.join(""));
    }, 2000);
  };

  return (
    <div className="bg-white p-2 mt-20 rounded-2xl font-outfit">
      <div>
        <div>
        <h4 className="text-text text-[20px] text-center">
          Enter OTP
        </h4>
        
        </div>
        <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-8">
          {/* OTP Input Boxes */}
          <div className="flex justify-center gap-x-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)} // Assign refs to input boxes
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                className="border-2 h-[75px] w-[75px] border-[#EAEAEA] rounded-xl p-4 text-center text-[28px]"
                maxLength={1}
                type="text"
              />
            ))}
          </div>

          {/* Resend OTP */}
          <div
            className="text-primary cursor-pointer items-center font-semibold text-center"
            onClick={() => console.log("Resend OTP")}
          ><span className="text-[14px] font-normal text-[#878787]">Did not Receive code ?{" "}</span>Resend</div>
          

          {/* Verify OTP Button */}
          <div className="mt-12">
          {!isloading ? (
            <button
            className="bg-primary h-10 w-full text-white font-outfit rounded-xl text-[14px] shadow-lg"
            onClick={verifyOtp}
          >
            Verify
          </button>
          ) : (
            <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
              <LoaderCircle className="animate-spin text-white" />
              <div className="text-white">Verifying your Otp</div>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otptest;
