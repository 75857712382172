import React, { useEffect, useState } from "react";
import { colleges } from "../constants/Strings";
import Select from "react-select";
import { getProfile } from "../utils/CommonFunctions";
import { handleMatchPress } from "../services/matchService";
import { useNavigate } from "react-router-dom";

type PopType = {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const MatchUser = ({ setOpenForm, openForm }: PopType) => {
  const [interest, setInterest] = useState<any>("");
  const [profileData, setProfileData] = useState<any>("");
  const [interestError, setInterestError] = useState<boolean>(false);
  const [collegeName, setCollegeName] = useState<any>("");
  const [className, setClassName] = useState<string>("text-white bg-[#6023D2]");
  const [femaleclassName, setfemaleClassName] = useState<string>("");
  const [male, setMale] = useState<boolean>(true);
  const [female, setFemale] = useState<boolean>(false);
  const navigate = useNavigate()
  useEffect(() => {
    getProfile(setProfileData);
  }, []);
  useEffect(() => {
    console.log(collegeName.value);
  }, [collegeName]);
  useEffect(() => {
    if (male) {
      setClassName("text-white bg-[#6023D2]");
    } else {
      setClassName("");
    }
  }, [male]);
  useEffect(() => {
    if (female) {
      setfemaleClassName("text-white bg-[#6023D2]");
    } else {
      setfemaleClassName("");
    }
  }, [female]);
  const handleFindMatch = async () => {
    if (!interest) {
      setInterestError(true);
      return;
    }
    let newInterestArray = profileData.interests;
    newInterestArray.push(interest);

    let payload = {};
    let gendersArray = [];
    if (male) gendersArray.push("Male");
    if (female) gendersArray.push("Female");
    payload = {
      genders: gendersArray,
      interests: newInterestArray,
      collegeName: [`${collegeName}`],
    };
    console.log(payload);
  };
  return (
    <div className="p-5 h-full flex flex-col gap-5 items-center">
      {/* Scrollable content */}
      <div
        className="overflow-y-auto w-full flex-1"
        style={{ maxHeight: "80%" }}
      >
        <div>
          <h1 className="text-xl font-outfit text-center">
            Find your study comparison
          </h1>
        </div>
        <div className="flex flex-col w-[100%] mt-5">
          <p className="font-outfit text-[18px] text-grey">Select gender</p>
          <div className="flex mt-2">
            <button
              className={`h-10 text-[#6023D2] font-outfit rounded-xl text-[14px] bottom-[24px] m-1 w-[103px] ${className}`}
              style={{
                border: "2px solid #CAC4D0",
              }}
              onClick={() => {
                setMale(!male);
              }}
            >
              Male
            </button>
            <button
              className={`h-10 text-[#6023D2] font-outfit rounded-xl text-[14px] bottom-[24px] m-1 w-[103px] ${femaleclassName}`}
              style={{
                border: "2px solid #CAC4D0",
              }}
              onClick={() => {
                setFemale(!female);
              }}
            >
              Female
            </button>
          </div>
        </div>
        <div className="flex flex-col w-[100%] mt-5">
          <p className="font-outfit text-[18px] text-grey">Interests</p>
          <div>
            <input
              className="w-full py-3 px-4 rounded-[16px] placeholder:text-[12px] text-[14px]  border-[1px]  bg-[#E9E5FD] mt-2 text-black"
              onChange={(e) => {
                setInterest(e.target.value);
                setInterestError(false);
              }}
              placeholder="Interests"
              type="email"
              value={interest}
            />
          </div>
        </div>
        {interestError && (
          <>
            <p className="text-red-500 text-[16px]">Type your interest</p>
          </>
        )}
        <div className="flex flex-col w-[100%] mt-5">
          <p className="font-outfit text-[18px] text-grey">College</p>
          <div className="flex items-center">
            <Select
              options={colleges}
              value={collegeName}
              onChange={(option) => {
                setCollegeName(option);
              }}
              className="w-full  mt-2 "
            />
          </div>
        </div>
      </div>
      {/* Button */}
      <button
        className="bg-primary h-10 text-white font-outfit rounded-xl text-[14px] shadow-lg bottom-[24px] w-[100%]"
        onClick={() => {
          handleMatchPress(
            interest,
            setInterestError,
            male,
            female,
            collegeName,
            navigate,
            setOpenForm
          );
        }}
      >
        FIND MATCH
      </button>
    </div>
  );
};

export default MatchUser;
