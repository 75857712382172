import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Post from "../components/Post";
import { useForum } from "../utils/Forum";
import TestNav from "../components/TestNav";
import Navbar from "../components/Navbar";
import "swiper/css";
import {
  acceptFriendRequest,
  rejectFriendRequest,
  fetchPendingRequests,
  getProfile,
} from "../utils/CommonFunctions";
const Home = () => {
  const [profileData, setProfileData] = React.useState<any>({});
  const [pendingRequests, setpendingRequests] = React.useState<any>([]);
  useEffect(() => {
    getProfile(setProfileData);
    fetchPendingRequests(setpendingRequests);
  }, []);
  const { posts } = useForum();
  const handleAccept = async (senderId: any) => {
    let payload = { senderId: senderId };
    await acceptFriendRequest(payload);
    await fetchPendingRequests(setpendingRequests);
  };
  const handleReject = async (senderId: any) => {
    let payload = { senderId: senderId };
    await rejectFriendRequest(payload);
    await fetchPendingRequests(setpendingRequests);
  };

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div className="px-4 py-5 flex flex-col gap-5 mb-12 font-outfit">
        <div>
          <h4 className="text-[24px] font-medium">Hi, {profileData?.name}</h4>
        </div>

        {/* New Matches */}
        {pendingRequests.length > 0 && (
          <>
            <div className="flx-row justify-between">
              <p className="text-base font-medium ">New Matches</p>
              <Link className="text-primary text-xs " to="/forum">
                See more
              </Link>
            </div>
            {pendingRequests.map((user: any) => (
              <div className="flex bg-white rounded-[24px] shadow-default px-4 py-4 border-[#C3B7F9] border-[0.3px] justify-between items-center ">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      src={user?.image}
                      className="max-h-12 max-w-12 rounded-xl"
                    />
                  </div>
                  <div>
                    <p className="font-medium text-[14px] leading-[20px] mt-1">
                      {user?.name}
                    </p>
                    <p className="font-light text-[12px] text-[#75737B] mt-1">
                      {user?.yearOfStudy}
                    </p>
                    <p className="font-light text-[12px] text-[#75737B] mt-1">
                      {user?.collegeName}
                    </p>
                  </div>
                </div>
                <div className="gap-4 flex">
                  <div>
                    <button onClick={() => handleAccept(user?.senderId)}>
                      <div className="flex bg-primary w-11 justify-center h-9 items-center rounded-xl">
                        <img src="/icons/tick.svg" />
                      </div>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => handleReject(user?.senderId)}
                      className="flex items-center justify-center border-primary border-2 w-11 h-9 rounded-xl"
                    >
                      <img src="/icons/cancel.svg" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* Medico Forum */}
        {posts && posts.length > 0 && (
          <div className="flex flex-col gap-3">
            <div className="flx-row justify-between">
              <p className="text-base font-medium ">Medico Forum</p>
              <Link className="text-primary text-xs " to="/forum">
                See more
              </Link>
            </div>
            <div className="flex-col gap-5 flex">
              {posts &&
                posts.length > 0 &&
                posts.slice(0, 1)?.map((item: any) => (
                  <div key={item?.id}>
                    <Post item={item} profileData={profileData} />
                  </div>
                ))}
            </div>
          </div>
        )}

        <TestNav />
      </div>
    </>
  );
};

export default Home;
