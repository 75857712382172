import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GoogleLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href; // Full URL
    let accessToken = null;

    // Parse the URL manually to find "accessToken"
    if (url.includes("?")) {
      const queryString = url.split("?")[1]; // Extract everything after "?"
      const queryParams = queryString.split("&"); // Split into individual params

      for (let param of queryParams) {
        const [key, value] = param.split("="); // Split "key=value"
        if (key === "accessToken") {
          accessToken = value;
          break; // Stop searching once the token is found
        }
      }
    }

    if (accessToken) {
      try {
        localStorage.setItem("accessToken", accessToken);
        navigate("/");
      } catch (error) {
        toast.error("An error occurred while logging in!");
        console.error("Error saving token:", error);
      }
    } else {
      toast.error("No access token found in the URL!");
    }
  }, [navigate, toast]);

  return null; // No UI needed for this component
};

export default GoogleLogin;
