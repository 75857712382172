import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { api } from "../../config/apiConfig";
import { toast } from "react-toastify";
import {
  getInstagramTimeFormat,
  getProfile,
} from "../../utils/CommonFunctions";

interface Message {
  _id: string;
  sender: string;
  text: string;
  createdAt: string;
}

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const { chatId } = useParams<{ chatId: string }>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const socket = useRef<any>(null);
  const location = useLocation();
  const { user, myId } = location.state;
  const [profileData, setProfileData] = React.useState<any>({});

  const messagesEndRef = useRef<HTMLDivElement | null>(null); // Explicit type for the ref

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll when the component mounts
  }, []);

  useEffect(() => {
    scrollToBottom(); // Scroll whenever messages update
  }, [messages]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const res = await api.get(`/chat/allmessages/${chatId}`);
        setMessages(res.data);
      } catch (err) {
        console.error("Error fetching messages:", err);
      }
    };
    fetchMessages();
    getProfile(setProfileData);
  }, [chatId]);

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(`${process.env.REACT_APP_SOCKET_URL}`);
      console.log("Socket connection established:", socket.current);
    }

    socket.current.on("connect", () => {
      console.log("Reconnected to the server");
      socket.current.emit("addUser", myId._id);
    });

    socket.current.on(
      "getMessage",
      (data: { senderId: string; text: string; conversationId: string }) => {
        if (data.conversationId === chatId) {
          setMessages((prev) => [
            ...prev,
            {
              _id: Date.now().toString(),
              sender: data.senderId,
              text: data.text,
              createdAt: new Date().toISOString(),
            },
          ]);
        }
      }
    );

    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, [chatId, myId._id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim()) {
      toast.error("Message cannot be empty");
      return;
    }

    const message = {
      sender: myId._id,
      text: newMessage,
      conversationId: chatId,
    };

    try {
      socket.current.emit("sendMessage", {
        senderId: myId._id,
        receiverId: user._id,
        text: newMessage,
        conversationId: chatId,
      });

      const res = await api.post(`/chat/new-message`, message);
      setMessages((prevMessages) => [...prevMessages, res.data]);
      setNewMessage("");
    } catch (err) {
      console.error("Error sending message:", err);
      toast.error("Failed to send message. Try again.");
    }
  };

  return (
    <div className="flex flex-col h-screen px-4">
      <div className="flex flex-row items-center justify-between mt-10">
        <div className="flex flex-row items-center gap-x-3">
          <button onClick={() => navigate("/chat")}>
            <img src="/icons/back-btn.svg" />
          </button>
          <p className="font-outfit text-text font-normal text-[14px]">{user.name}</p>
        </div>
      </div>
      <hr className="text-[#E9E5FD] my-6" />
      <div className="flex-1 overflow-y-auto mb-16">
        {messages.map((message) => (
          <div
            key={message._id}
            className={`flex ${
              message.sender === myId._id ? "justify-end" : "justify-start"
            } mb-4`}
          >
            {message.sender !== myId._id && (
              <img
                className="rounded-[8px] w-[32px] h-[32px] mr-2 "
                src={user?.image?.url}
                alt=""
              />
            )}
            <div>
              <div
                className={`relative py-2 px-3 rounded-[8px] ${
                  message.sender === myId._id
                    ? "bg-[#E9E5FD] text-left"
                    : "bg-[#F3F4F7] text-left"
                } max-w-[300px]`}
              >
                <span className="font-outfit text-[14px]">{message.text}</span>
              </div>
              <div className="text-gray-500 text-[12px]">
                {getInstagramTimeFormat(message.createdAt)}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Invisible div for auto-scrolling */}
      </div>
      <form onSubmit={handleSubmit} className="fixed bottom-6 left-0 w-full px-4 bg-white">
        <div className="flex items-center gap-2 mt-1">
          <textarea
            placeholder="Send a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-1 bg-[#F4F2FE] placeholder:text-[14px] placeholder:text-text/70 font-outfit
             rounded-[28px] focus:outline-none focus:ring-2 focus:ring-primary px-4 py-2 resize-none"
            rows={1}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          />
          <button
            type="submit"
            className="w-10 h-10 flex items-center justify-center bg-transparent"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.39999 6.32015L15.89 3.49015C19.7 2.22015 21.77 4.30015 20.51 8.11015L17.68 16.6002C15.78 22.3102 12.66 22.3102 10.76 16.6002L9.91999 14.0802L7.39999 13.2402C1.68999 11.3402 1.68999 8.23015 7.39999 6.32015Z"
                stroke="#6023D2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.11 13.6501L13.69 10.0601"
                stroke="#6023D2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Chat;
