import  { useEffect, useState } from "react";
import Post from "../components/Post";
import PostPop from "../components/popup/PostPop";
import PostInputPop from "../components/popup/PostInputPop";
import { useForum } from "../utils/Forum";
import Navbar from "../components/Navbar";
import React from "react";
import { getProfile } from "../utils/CommonFunctions";

const Forum = () => {
  const [open, setOpen] = useState(false);
  const [currPost, setCurrPost] = useState();
  const [openForm, setOpenForm] = useState(false);
  const { posts, handleGetCurrPost } = useForum();
  const [profileData, setProfileData] = React.useState<any>({});
  useEffect(()=>{
    getProfile(setProfileData)
  },[])
  return (
    <div>
      <Navbar />
    <div className="text-md flex flex-col gap-5 mb-[124px]">
      
      {/* <Heading title="Duo Forum" /> */}
      <div className="flex-col gap-5 flex px-[1rem]">
        {posts &&
          posts.length > 0 &&
          posts?.map((item: any, id) => (
            <div
              key={id}
              onClick={async () => {
                setCurrPost(await handleGetCurrPost(item?._id));
                setOpen(!open);
              }}
            >
              <Post  item={item} profileData = {profileData} />
            </div>
          ))}
      </div>

      {/* FAB Button */}
      <button
        className="fixed bottom-[98px] right-5"
        onClick={() => setOpenForm(!openForm)}
      >
        {/* Replace with the provided SVG icon */}
        <img
          src="/icons/FAB.svg"
          alt="FAB Icon"
          className=" shadow-2xl shadow-primary/50 rounded-2xl"
        />
      </button>

      {open && <PostPop open={open} setOpen={setOpen} currPost={currPost} profileData={profileData} />}
      {openForm && (
        <PostInputPop openForm={openForm} setOpenForm={setOpenForm} />
      )}
    </div>
    </div>
  );
};

export default Forum;
