import React from "react";
import Heading from "../../components/Heading";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { Avatar } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../context/AuthContext";
import { useRoom } from "../../utils/Room";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
const CreateRoom = () => {
  const { pathname } = useLocation();
  const {account}=useAuth()
  const {createRoom,roomName,setRoomName,roomInvite,setRoomInvite,ids,setIds,id,setId}=useRoom()

  return (
    <div className="pt-[0.5rem] text-md flex flex-col min-h-screen">
      <Heading title="Create Room" />

      <div className=" mt-10 py-[2rem] px-[1rem]  flex-1 flex flex-col gap-5 h-full">
        <div className="flex flex-col gap-2">
          <div className="flx-row justify-between text-md text-darkgrey">
            <label className="font-bold">Create Room Name</label>
            {/* <span className="text-xs text-purple">Edit name</span> */}
          </div>
          <div className="w-full h-12 bg-[#F2F6FA] border border-[#E1E8F1] rounded-md outline-none flx-row justify-between px-3 ">
            <input
              type="text"
              placeholder="Create Room"
              className=" px-3 flex-1 bg-[#F2F6FA]"
              value={roomName}
              onChange={(e)=>setRoomName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flx-row justify-between text-md text-darkgrey">
            <label className="font-bold">Room Admin</label>
            {/* <span className="text-xs text-purple">Change Admin</span> */}
          </div>
          <div className="flx-row justify-between">
            <div className="flx-row gap-2 text-sm">
              <Avatar
                alt="Remy Sharp"
                src={account?.profilePic}
                sx={{ width: 50, height: 50 }}
              />
              <div>
                <p className="font-semibold">{account?.name}</p>
                <p className="text-xs">Group Admin</p>
              </div>
            </div>
            {/* <div className="flx-row gap-2 text-sm">
              <button className="p-3 border-dashed border-2 rounded-full">
                <AddOutlinedIcon />
              </button>
              <div>
                <p className="font-semibold">+ Add Admin</p>
                <p className="text-xs">Group Admin</p>
              </div>
            </div> */}
          </div>
        </div>
        <p className="font-bold">Invited Members</p>
        <div className="flx-row gap-3 justify-between">
          <div className="flx-row  flex-1 gap-3">
            {ids && ids.length>0 && ids.map((item)=>(
              <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <CloseIcon className="bg-[#01BCB0]  rounded-full text-xs p-1"  onClick={()=>{
                  setIds(ids.filter((i)=>i!==item))
                }}/>
              }
            >
              <Avatar
                alt={item}
                src="/static/images/avatar/2.jpg"
                sx={{ width: 60, height: 60 }}
              />
            </Badge>))}
            
          </div>
          {/* <button className="p-4 border-dashed border-2 rounded-full">
            <AddOutlinedIcon />
          </button> */}
        </div>
        <div className="flex flex-col  gap-3 justify-between">
        <div className="w-full h-12 bg-[#F2F6FA] border border-[#E1E8F1] rounded-md outline-none flx-row justify-between px-3 ">
              <input
                type="text"
                placeholder="Enter Username"
                className=" px-3 flex-1 bg-[#F2F6FA]"
                value={id}
                onChange={(e)=>{
                    setId(e.target.value)}}
              />
            </div>
          <button className="rounded-md bg-purpleGradient p-3 text-white " onClick={()=>{
            if(!ids.includes(id)){
              setIds([...ids,id])
              setId("")
          }}}>
            Add Member
          </button>
        </div>
        <div className="flex flex-col gap-2">
          {/* <p className="font-bold">Invited Friends</p> */}
          {/* <div className="flx-row justify-between h-20 gap-5">
            <div className="w-full h-12 bg-[#F2F6FA] border border-[#E1E8F1] rounded-md outline-none flx-row justify-between px-3 ">
              <input
                type="text"
                placeholder="Room url"
                className=" px-3 flex-1 bg-[#F2F6FA]"
              />
            </div>{" "}
            <button className="rounded-md bg-purple p-3 text-white w-40">
              Copy Url
            </button>
          </div> */}
          <button className="rounded-md bg-purpleGradient p-3 text-white " onClick={createRoom}>
            Create Room
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRoom;
