import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import { PulseLoader } from "react-spinners";
import { getProfile } from "../../utils/CommonFunctions";
import { toast } from "react-toastify";

const TournamentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [tournamentDetails, setTournamentDetails] = useState<any>(null);
  const [profileData, setProfileData] = useState<any>(null);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [hasAttempted, setHasAttempted] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number | null>(null);

  // Fetch user profile
  useEffect(() => {
    getProfile(setProfileData);
  }, []);

  // Fetch tournament details and check registration and attempt status
  useEffect(() => {
    if (!profileData) return;
    const fetchTournamentData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(`${backendUrl}/tournaments/details/${id}`);
        const { data } = await response.json();

        setTournamentDetails(data);

        // Check registration
        if (data.registeredEmails && profileData.email) {
          const userRegistered = data.registeredEmails.some(
            (reg: any) => reg.email === profileData.email
          );
          setIsRegistered(userRegistered);
        }

        // Check if already attempted
        if (data.attempts && profileData._id) {
          const userAttempted = data.attempts.some(
            (attempt: any) => attempt.userId === profileData._id
          );
          setHasAttempted(userAttempted);
        }
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentData();
  }, [id, profileData]);

  // Countdown for the tournament
  useEffect(() => {
    if (tournamentDetails?.scheduledTime) {
      const updateCountdown = () => {
        const now = new Date().getTime();
        const tournamentTime = new Date(
          tournamentDetails.scheduledTime
        ).getTime();
        const diff = tournamentTime - now;

        if (diff > 0) {
          setCountdown(Math.floor(diff / 1000)); // Countdown in seconds
        } else {
          setCountdown(null);
        }
      };

      updateCountdown();
      const interval = setInterval(updateCountdown, 1000);
      return () => clearInterval(interval);
    }
  }, [tournamentDetails]);

  // Register user for the tournament
  const handleRegister = async () => {
    if (loading) return; // Prevent multiple clicks
  
    setLoading(true); // Set loading to true at the start of the function
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No token found");
  
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(
        `${backendUrl}/tournaments/register-tournament/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email: profileData.email }),
        }
      );
  
      const result = await response.json();
      if (result?.status === "success") {
        setIsRegistered(true);
        toast.success("Successfully registered for the tournament!");
      } else {
        toast.error(result.message || "Registration failed.");
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error("An error occurred while registering. Please try again.");
    } finally {
      setLoading(false); // Reset loading to false after completion
    }
  };
  

  // Convert UTC time to IST
  const getISTTime = (utcTime: string) => {
    const scheduledTimeUTC = new Date(utcTime);
    return scheduledTimeUTC.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  };

  // Handle Join Tournament
  const handleJoinTournament = () => {
    if (hasAttempted) {
      toast.error("You have already attempted this tournament.");
    } else {
      navigate(`/tournament-questions/${id}`);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <PulseLoader color="#7A54F0" loading={loading} />
      </div>
    );
  }
  
  return (
    <>
      {tournamentDetails && (
        <div className="bg-lightestBlue min-h-screen font-outfit">
          <div className="px-4 py-8">
            <div className="flex flex-row items-center gap-x-3">
              <button onClick={() => navigate("/quiz-list",{ state: { activeTab: "Events" } })}>
                <img src="/icons/back-btn.svg" alt="Back" />
              </button>
              <p className="font-outfit text-primary text-[14px]">
                Tournament Details
              </p>
            </div>
          </div>
          <div className="px-4">
            <p className="text-[20px]">
              Brief explanation about the tournament
            </p>
            <div className="flex flex-col gap-6 mt-6 mb-10">
              <div className="flex gap-3">
                <div>
                  <img src="/icons/questions-icon.svg" alt="Questions" />
                </div>
                <div>
                  <p className="text-[16px] text-left text-text">
                    {tournamentDetails.numberOfQuestions} Questions
                  </p>
                  <p className="font-roboto text-[14px] text-left text-text">
                    2 points for each correct answer
                  </p>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <img src="/icons/time-icon-quiz.svg" alt="Time" />
                </div>
                <div>
                  <p className="font-roboto text-[16px] text-left">
                    {tournamentDetails.duration} Minutes
                  </p>
                  <p className="font-roboto text-[14px] text-left text-grey-400">
                    Total duration of the tournament
                  </p>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <img src="/icons/duocoin-icon-quiz.svg" alt="Prize" />
                </div>
                <div>
                  <p className="font-roboto text-[16px] text-left">
                    Win {tournamentDetails.prize || 10} coins
                  </p>
                  <p className="font-roboto text-[14px] text-left text-textBlack">
                    Prize money rewarded after the tournament
                  </p>
                </div>
              </div>
            </div>
            <p className="font-roboto text-text text-[16px] font-medium mb-[24px]">
              Before you begin the tournament, please read the terms and
              conditions properly.
            </p>
            <ul className="m-0 px-4 mb-6 list-disc flex flex-col gap-2 text-formfieldtext">
              <li className="text-[14px]">
                Tournament will begin at{" "}
                {getISTTime(tournamentDetails.scheduledTime)}.
              </li>
              <li className="text-[14px]">
                Registration will close 5 minutes prior to commencement of quiz.
              </li>
              <li className="text-[14px]">
                {tournamentDetails.reward} coins awarded only for answering all
                questions correctly.
              </li>
              <li className="text-[14px]">
                Entry fee will be deducted from the wallet upon starting the
                tournament.
              </li>
              <li className="text-[14px]">
                Tap on options to select the correct answer.
              </li>
              <li className="text-[14px]">
                Tournament will auto-submit once time is up.
              </li>
              <li className="text-[14px]">
                Each participant can attempt only once.
              </li>
            </ul>
            <div className="mt-10">
              {isRegistered ? (
                countdown && countdown > 0 ? (
                  <ActionButton
                    buttonClass=""
                    buttonText={`Time Left: ${Math.floor(countdown / 60)}m ${
                      countdown % 60
                    }s`}
                    onClick={() => {}}
                  />
                ) : (
                  <ActionButton
                    buttonClass=""
                    buttonText="Join Tournament"
                    onClick={handleJoinTournament}
                  />
                )
              ) : new Date(tournamentDetails.scheduledTime).getTime() -
                  Date.now() >
                300000 ? (
                <ActionButton
                  buttonClass=""
                  buttonText={loading ? "Registering..." : "Register"}
                  onClick={handleRegister}
                  disabled={loading} // Disable button while loading is true
                />
              ) : (
                <ActionButton
                  buttonClass=""
                  buttonText="Registration closed"
                  onClick={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TournamentDetails;
