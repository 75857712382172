import React, { useEffect, useState } from "react";
import { getProfile } from "../utils/CommonFunctions";
import { useNavigate } from "react-router-dom";

const Recharge = () => {
  const navigate = useNavigate();
  const [profiledata, setProfileData] = useState<any>();
  const [amount, setAmount] = useState<string>("");

  useEffect(() => {
    getProfile(setProfileData);
  }, []);

  const handleSetAmount = (value: number) => {
    setAmount(value.toString());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Allow only numbers or empty string
    if (/^\d*$/.test(inputValue)) {
      setAmount(inputValue);
    }
  };

  const handleProceed = () => {
    if (amount) {
      navigate(`/next-page?amount=${amount}`);
    } else {
      alert("Please enter an amount before proceeding.");
    }
  };

  return (
    <div>
      <div className="px-4 py-8 flex flex-col h-screen w-screen font-outfit">
        <div className="flex flex-row items-center gap-x-3">
          <button onClick={() => navigate("/")}>
            <img src="/icons/back-btn.svg" alt="Back" />
          </button>
          <p className="font-outfit text-primary font-normal text-[14px]">
            DuoBalance Recharge
          </p>
        </div>
        {/* Balance card */}
        <div className="bg-[#E9E5FD] rounded-[16px] w-full mt-9">
          <div className="p-6">
            <p className="text-[24px] text-text mb-1">My DuoBalance</p>
            <div className="flex gap-2 items-center">
              <img src="/icons/duocoin.png" className="w-10 h-10" alt="DuoCoin" />
              <span className={`text-[36px] ${
                  profiledata?.coins < 50 ? "text-red-500" : "text-text"
                }`}>{profiledata?.coins}</span>
            </div>
          </div>
        </div>
        {/* Info Note */}
        <div>
          <p className="text-text text-[14px] mt-6">
            Note: Coins in your Duobalance are equivalent to real cash. 1 coin = ₹1.
          </p>
        </div>
        {/* Input to Enter Amount for Recharging */}
        <div className="mt-12">
          <label className="text-text text-[14px] mb-2 font-outfit">Enter Amount</label>
          <input
            type="text"
            value={amount}
            onChange={handleInputChange}
            className="w-full h-10 border border-[#A088F5] rounded-[16px] px-3 py-2 text-formfieldtext font-outfit"
            placeholder="₹ 20"
          />
          <div className="flex justify-between mt-6">
            {[20, 50, 100, 200, 500].map((value) => (
              <button
                key={value}
                onClick={() => handleSetAmount(value)}
                className="text-text border-[#A088F5] border py-2 px-4 rounded-[8px] shadow-sm"
              >
                + {value}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-[40px]">
          <button
            onClick={handleProceed}
            className="bg-primary h-10 text-white font-outfit rounded-xl text-[14px] shadow-lg w-full"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Recharge;
