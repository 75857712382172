// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
import { IoIosArrowDropleft } from "react-icons/io";
import COLORS from "../constants/Colors";
import FormInput from "../components/FormInput";
function ForgotPassword() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(false);
  const [sent, setsent] = useState(false);

  const sendCode = async () => {
    if (!email) {
      toast.error("Please enter email address");
      return;
    }
    setisloading(true);
    try {
      const res = await axios.post(`${BACKEND_URL}/auth/forgot-password`, {
        email,
      });

      toast.success(res.data.message);
      setisloading(false);
      setsent(true);
      navigate(`/verify/code/${encodeURIComponent(email)}`);
    } catch (err) {
      toast.error(err.response.data.message);
      setisloading(false);
    }
  };

  return (
      <div className="h-screen w-screen flex flex-col items-center justify-center font-outfit px-4">
        <div className="w-full">
          <h4 className="text-text text-[20px] text-center">Forgot Password</h4>
          <div className="flex flex-col gap-y-8 mt-8">
            <div>
              <FormInput
                placeholder="Enter your Email ID"
                className=""
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {!isloading ? (
              <div className="">
                <ActionButton
                  buttonClass=""
                  buttonText="Continue"
                  onClick={sendCode}
                />
              </div>
            ) : (
              <div className="bg-primary flex justify-around items-center rounded-xl p-2">
                <LoaderCircle className="animate-spin text-white" />
                <div className="text-white">Sending Code...</div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-20">
            <p className="text-text font-outfit font-normal text-[14px] text-center">
              Try logging into your account?{" "}
              <Link
                to="/login"
                className="text-[#313035] text-[14px] font-medium"
              >
                Login Now
              </Link>
            </p>
          </div>
      </div>
    );
}

export default ForgotPassword;
