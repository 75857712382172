import React from "react";

interface ActionButtonProps {
  buttonStyle?: any;
  buttonClass?: string;
  buttonText: string;
  disabled?: boolean;
  onClick: () => void;
}

const ActionButton = ({
  buttonStyle,
  disabled,
  buttonClass,
  buttonText,
  onClick,
}: ActionButtonProps) => {
  return (
    <button
      className={`h-10 bg-primary rounded-xl text-[14px] font-medium text-white ${buttonClass}`}
      style={{ width: "100%", ...buttonStyle }}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
};

export default ActionButton;
